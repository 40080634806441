import { styled } from "@mui/material/styles";
import { FormControl } from "@mui/material";

export const HeaderSelectContainer = styled(FormControl)(({ theme }) => ({
  marginLeft: "20px",
  marginTop: "0",
  marginBottom: "0",
  width: "300px",
  margin: "5px",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0",
    marginTop: "10px",
    marginBottom: "10px",
    width: "95%",
  },
}));
