import React from "react";
import { LinearProgress, styled } from "@mui/material";

const BibliographyLoadingContainer = styled("div")({
  height: "10px",
  margin: "10px auto",
  width: "100%",
});

export const BibliographyLoading = ({ showLoading }) => (
  <BibliographyLoadingContainer>{showLoading && <LinearProgress />}</BibliographyLoadingContainer>
);
