export const manualViewsConfig = [
    {
        mediaType: 'Zeitschriftenartikel',
        cslType: "article-journal",
        description: "Ein Artikel aus einer wissenschaftlichen oder Fachzeitschrift.",
        popularType: true,
        mainProperties: [
            "title",
            "short-title",
            "container-title",
            "collaborators",
            "volume",
            "issue",
            "locator",
            "issued",
            "archive",
            "page",
            "DOI",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        collaboratorsRecommendedRoles: ["author", "translator"]
    },
    {
        mediaType: "Buch",
        cslType: "book",
        description: "Ein gedrucktes oder digitales Werk, das aus mehreren Seiten Text und eventuell Bildern besteht.",
        popularType: true,
        mainProperties: [
            "title",
            "short-title",
            "collaborators",
            "edition",
            "volume",
            "medium",
            "issued",
            "publisher",
            "publisher-place",
            "ISBN",
            "page",
            "DOI",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        collaboratorsRecommendedRoles: ["author", "editor", "translator"]
    },
    {
        mediaType: "Webseite",
        cslType: "webpage",
        description: "Online-Webseite.",
        popularType: true,
        mainProperties: [
            "title",
            "container-title",
            "issued",
            "accessed",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "container-title": {
                "title": "Webseite Name",
                "description": "Die Name der Webseite."
            }
        }
    },
    {
        mediaType: "Online-Video",
        cslType: "motion_picture",
        description: "Ein Video, das auf einer Internetplattform wie YouTube oder Vimeo verfügbar ist.",
        popularType: true,
        mainProperties: [
            "title",
            "short-title",
            "container-title",
            "collaborators",
            "issued",
            "accessed",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "container-title": {
                "title": "Webseite Name",
                "description": "Der Name der Webseite, auf der das Video hochgeladen wurde."
            }
        },
        collaboratorsRecommendedRoles: ["author", "editor", "director"]
    },
    {
        mediaType: "Online-Zeitungsartikel",
        cslType: "article-newspaper",
        description: "Ein Artikel, der auf der Webseite einer Zeitung veröffentlicht wurde.",
        popularType: true,
        mainProperties: [
            "short-title",
            "title",
            "container-title",
            "collaborators",
            "issued",
            "accessed",
            "publisher",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "container-title": {
                "title": "Webseite Name",
                "description": "Der Name der Webseite."
            }
        },
        collaboratorsRecommendedRoles: ["author", "editor", "translator"]
    },
    {
        mediaType: "Kunstwerk",
        cslType: "graphic",
        description: "Ein physisches oder digitales Werk der bildenden Künste, wie ein Gemälde oder eine Skulptur.",
        mainProperties: [
            "title",
            "short-title",
            "collaborators",
            "medium",
            "issued",
            "archive",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "archive": {
                "title": "Archiv / Bibliothek / Museum",
                "description": "Name des Archivs, der Bibliothek oder des Museums, in dem das Kunstwerk aufbewahrt wird."
            },
        },
        collaboratorsRecommendedRoles: ["author"]
    },
    {
        mediaType: "Blogbeitrag",
        cslType: "post-weblog",
        description: "Ein informeller oder diskussionsbasierter Artikel, der auf einer Blog-Plattform veröffentlicht wird.",
        mainProperties: [
            "title",
            "short-title",
            "container-title",
            "collaborators",
            "issued",
            "archive",
            "accessed",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "container-title": {
                "title": "Blogname",
                "description": "Name des Blogs, in dem der Blogbeitrag veröffentlicht ist."
            },
        },
        collaboratorsRecommendedRoles: ["author"]
    },
    {
        mediaType: "Buchkapitel",
        cslType: "chapter",
        description: "Ein bestimmter Abschnitt oder ein Kapitel aus einem Buch.",
        mainProperties: [
            "title",
            "short-title",
            "container-title",
            "collaborators",
            "edition",
            "volume",
            "medium",
            "issued",
            "publisher",
            "source",
            "page",
            "DOI",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "container-title": {
                "title": "Buchtitel",
                "description": "Titel des Buches, das das Kapitel enthält."
            },
        },
        collaboratorsRecommendedRoles: ["author", "editor", "translator"]
    },
    {
        mediaType: "Online-Abbildung",
        cslType: "image",
        description: "Ein Bild oder eine Grafik, die online verfügbar ist.",
        mainProperties: [
            "title",
            "short-title",
            "container-title",
            "collaborators",
            "issued",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "container-title": {
                "title": "Webseite Name",
                "description": "Der Name der Webseite."
            }
        },
        collaboratorsRecommendedRoles: ["author"]
    },
    {
        mediaType: "Abschlussarbeit",
        cslType: "thesis",
        description: "Eine wissenschaftliche Arbeit, die als Teil des Abschlusses an einer Hochschule oder Universität eingereicht wird.",
        mainProperties: [
            "title",
            "collaborators",
            "genre",
            "issued",
            "publisher",
            "DOI",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "publisher": {
                "title": "Universität",
                "description": "Universität, die die Abschlussarbeit veröffentlicht hat."
            }
        },
        collaboratorsRecommendedRoles: ["author"]
    },
    {
        mediaType: "Film",
        cslType: "motion_picture",
        description: "Ein Film, der für das Kino, das Fernsehen oder das Internet produziert wurde.",
        mainProperties: [
            "title",
            "collaborators",
            "version",
            "medium",
            "issued",
            "publisher",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "publisher": {
                "title": "Produktionsgesellschaft",
                "description": "Produktionsgesellschaft, die den Film veröffentlicht hat."
            }
        },
        collaboratorsRecommendedRoles: ["director"]
    },
    {
        mediaType: "Foreneintrag",
        cslType: "post",
        description: "Ein Beitrag in einem Online-Forum oder Diskussionsboard.",
        mainProperties: [
            "title",
            "container-title",
            "collaborators",
            "issued",
            "accessed",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "container-title": {
                "title": "Webseite Name",
                "description": "Der Name der Webseite."
            }
        },
        collaboratorsRecommendedRoles: ["author"]
    },
    {
        mediaType: "Kommentar",
        cslType: "post",
        description: "Eine kurze, oft persönliche Meinungsäußerung oder Beobachtung.",
        mainProperties: [
            "title",
            "container-title",
            "collaborators",
            "issued",
            "accessed",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            overrides: {
                "container-title": {
                    "title": "Webseite Name",
                    "description": "Der Name der Webseite."
                }
            },
        },
        collaboratorsRecommendedRoles: ["author"]
    },
    {
        mediaType: "Social-Media-Beitrag",
        cslType: "post",
        description: "Ein Beitrag auf einer Social-Media-Plattform wie Facebook, Twitter oder Instagram.",
        mainProperties: [
            "title",
            "container-title",
            "collaborators",
            "issued",
            "accessed",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "container-title": {
                "title": "Webseite Name",
                "description": "Der Name der Webseite."
            }
        },
        collaboratorsRecommendedRoles: ["author"]
    },
    {
        mediaType: "Wikipedia-Eintrag",
        cslType: "entry-encyclopedia",
        description: "Ein Artikel in der Online-Enzyklopädie Wikipedia.",
        mainProperties: [
            "title",
            "container-title",
            "issued",
            "accessed",
            "URL",
            "note"
        ],
        requiredProperties: ["title"],
        overrides: {
            "container-title": {
                "title": "Wiki Titel",
                "description": "Der Name des Wikipedia-Artikels."
            }
        },
        collaboratorsRecommendedRoles: ["author"]
    },
    {
        mediaType: "Sonstige",
        hidden: true,
        fallback: true,
        mainProperties: [
            "title",
            "short-title",
            "collaborators",
            "edition",
            "volume",
            "issue",
            "locator",
            "medium",
            "issued",
            "publisher",
            "publisher-place",
            "archive",
            "ISBN",
            "page",
            "DOI",
            "URL",
            "note"
        ],
        requiredProperties: ["title"]
    }
]
