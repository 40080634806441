import { Alert, Box, CircularProgress, Snackbar, styled } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useCitationStyle } from "../hooks/useCitationStyle";
import { useClipboard } from "../hooks/useClipboard";
import { SCIENTIFIC_LITERATURE } from "../hooks/useMainLayout";
import { useSnackbar } from "../hooks/useSnackbar";
import { getAvailableStyles } from "../logic/citationApi";
import { createNewManualEntry, editEntry, updateCitationOutput } from "../logic/citationGeneration";
import { MainContainer } from "./MainContainer";
import { CitationTypeChoice } from "./citationChoice/CitationTypeChoice";
import { MainLayout } from "./mainLayout/MainLayout";
import { ManualCitation } from "./manualCitation/ManualCitation";
import { Preferences } from "./preferences/Preferences";

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    alignItems: "stretch",
  },
}));

const CitationStyleContainer = styled(ContentContainer)({
  alignItems: "center",
});

const GlobalLoadingContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const CitationDashboard = ({ data, setData, style, setStyle, sortOption, setSortOption, language, setLanguage }) => {
  const [availableCitationStyles, setAvailableCitationStyles] = useState([]);

  useCitationStyle(availableCitationStyles, style, setStyle);

  const [manualEdition, setManualEdition] = useState(false);
  const [selectedCitation, setSelectedCitation] = useState(undefined);

  const [newManualCitationOpen, setNewManualCitationOpen] = useState(false);
  const [newCitationMediaType, setNewCitationMediaType] = useState(undefined);

  const [showPreferences, setShowPreferences] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const [isGlobalLoading, setGlobalLoading] = useState(false);

  const { openSnackbar, closeSnackbar, snackbarOpen, snackbarSeverity, snackbarText } = useSnackbar();

  const { copyBibliography, copyBibliographyEntry, copyInTextCitation } = useClipboard(data, openSnackbar);

  const [selectedMediaTypes, setSelectedMediaTypes] = useState([]);

  const [resultType, setResultType] = useState(SCIENTIFIC_LITERATURE);

  const rootRef = useRef(null);

  const scrollToRoot = useCallback(() => {
    const stickyHeaderHeight = 100;

    if (rootRef.current) {
      const rect = rootRef.current.getBoundingClientRect();

      if (rect.top < stickyHeaderHeight) {
        const topPosition = rect.top + window.scrollY - stickyHeaderHeight;

        window.scrollTo({
          top: topPosition,
          behavior: "instant",
        });
      }
    }
  }, [rootRef]);

  const setError = useCallback(
    errorMessage => {
      openSnackbar(errorMessage, "error");
    },
    [openSnackbar]
  );

  useEffect(() => {
    setGlobalLoading(true);
    getAvailableStyles()
      .then(data => {
        setAvailableCitationStyles(data);
        setGlobalLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setGlobalLoading(false);
      });
  }, [setError]);

  useEffect(() => {
    if (style) {
      updateCitationOutput(data, style, sortOption, language, setData, setError, setLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style, language]);

  const resetMediaTypes = useCallback(
    data => {
      setSelectedMediaTypes([]);
      return data.map(item => {
        const { hidden, ...rest } = item;
        return rest;
      });
    },
    [setSelectedMediaTypes]
  );

  const submitCitationEdit = formData => {
    setManualEdition(false);
    if (!selectedCitation) {
      createNewManualEntry(resetMediaTypes(data), formData, style, sortOption, language, setData, setError, setLoading);
    } else {
      editEntry(resetMediaTypes(data), formData, style, sortOption, language, setData, setError, setLoading);
      setSelectedCitation(undefined);
    }
    scrollToRoot();
  };

  const onCitationEdit = citation => {
    scrollToRoot();
    setSelectedCitation(citation);
    setManualEdition(true);
  };

  const onEditCancel = () => {
    setSelectedCitation(undefined);
    setManualEdition(false);
    setNewCitationMediaType(undefined);
    scrollToRoot();
  };

  const renderNewManualCitationChoice = () => {
    return (
      <ContentContainer>
        <CitationTypeChoice
          onCitationSelect={mediaType => {
            setNewCitationMediaType(mediaType);
            setNewManualCitationOpen(false);
            setManualEdition(true);
            scrollToRoot();
          }}
          goBack={() => {
            setNewManualCitationOpen(false);
            scrollToRoot();
          }}
        />
      </ContentContainer>
    );
  };

  const renderManualEdition = () => {
    return (
      <ContentContainer>
        <ManualCitation
          citationData={selectedCitation}
          onCitationSubmit={submitCitationEdit}
          onCancel={onEditCancel}
          mediaTypeForNewEntry={newCitationMediaType}
          moveScroll={scrollToRoot}
        />
      </ContentContainer>
    );
  };

  const renderMainLayout = () => {
    return (
      <ContentContainer>
        <MainLayout
          setShowPreferences={setShowPreferences}
          setNewManualCitationOpen={setNewManualCitationOpen}
          onCitationEdit={onCitationEdit}
          copyInTextCitation={copyInTextCitation}
          copyBibliographyEntry={copyBibliographyEntry}
          style={style}
          data={data}
          setData={setData}
          setError={setError}
          copyBibliography={copyBibliography}
          isBibliographyLoading={isLoading}
          setBibliographyLoading={setLoading}
          setSortOption={setSortOption}
          sortOption={sortOption}
          language={language}
          selectedMediaTypes={selectedMediaTypes}
          setSelectedMediaTypes={setSelectedMediaTypes}
          resetMediaTypes={resetMediaTypes}
          resultType={resultType}
          setResultType={setResultType}
          availableCitationStyles={availableCitationStyles}
        />
      </ContentContainer>
    );
  };

  const renderPreferences = () => (
    <CitationStyleContainer>
      <Preferences
        hidePreferences={() => setShowPreferences(false)}
        style={style}
        setStyle={setStyle}
        language={language}
        setLanguage={setLanguage}
        availableCitationStyles={availableCitationStyles}
      />
    </CitationStyleContainer>
  );

  const renderView = () => {
    if (isGlobalLoading) {
      return (
        <GlobalLoadingContainer>
          <CircularProgress />
        </GlobalLoadingContainer>
      );
    }
    if (newManualCitationOpen) {
      return renderNewManualCitationChoice();
    }

    if (manualEdition) {
      return renderManualEdition();
    }

    if (showPreferences) {
      return renderPreferences();
    }

    return renderMainLayout();
  };

  return (
    <div className="mainContainer" ref={rootRef}>
      <MainContainer>{renderView()}</MainContainer>
      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarText}
        </Alert>
      </Snackbar>
    </div>
  );
};
