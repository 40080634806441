const { useState, useCallback } = require("react");

export const useSnackbar = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState(undefined);
  const [snackbarSeverity, setSnackbarSeverity] = useState(undefined);

  const openSnackbar = useCallback(
    (text, severity) => {
      setSnackbarText(text);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    },
    [setSnackbarText, setSnackbarSeverity, setSnackbarOpen]
  );

  const closeSnackbar = useCallback(() => setSnackbarOpen(false), [setSnackbarOpen]);

  return { openSnackbar, closeSnackbar, snackbarOpen, snackbarSeverity, snackbarText };
};
