import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import BusinessIcon from "@mui/icons-material/Business";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import { Button, ButtonGroup, FormControl, IconButton, ListSubheader, MenuItem, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { memo } from "react";
import { roles, rolesTranslated } from "../../../config/schemaPropertiesGrouping";
import { SelectInput } from "../../input/SelectInput";

const ButtonContainer = styled("div")(({ theme }) => ({
  margin: "5px 10px 10px 10px",
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const FieldsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  alignItems: "center",
  justifyContent: "space-between",
  width: `100%`,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

const InputContainer = styled("div")({
  flexGrow: 1,
  margin: "16px 0",
});

const RoleInputContainer = styled("div")(({ theme }) => ({
  width: "200px",
  margin: "16px 0",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ControlIconsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    margin: "15px",
    gap: "10px",
  },
}));

const CollaboratorContainer = styled("div")(({ theme }) => ({
  padding: "16px",
  margin: "24px 16px",
  boxShadow: "0 0 8px 4px rgba(152,152,152,0.2)",
  borderRadius: "10px",
  minWidth: "500px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    minWidth: "300px",
    margin: "24px 0",
  },
}));

const CollaboratorFieldsInternal = ({
  collaborator,
  index,
  updateCollaboratorCallback,
  removeSelfCallback,
  moveCollaboratorUpCallback,
  moveCollaboratorDownCallback,
  collaboratorsRecommendedRoles,
}) => {
  const { mode, family, given, organization, role } = collaborator;

  const updateCollaborator = data => updateCollaboratorCallback(index, data);
  const removeSelf = () => removeSelfCallback(index);
  const moveUp = () => moveCollaboratorUpCallback(index);
  const moveDown = () => moveCollaboratorDownCallback(index);

  const getCollaboratorMode = () => {
    if (mode) {
      return mode;
    }

    return organization ? "Organisation" : "Person";
  };

  const collaboratorMode = getCollaboratorMode();

  const getOtherRoles = recommendedRoles => {
    if (recommendedRoles?.length > 0) {
      return roles.filter(item => !recommendedRoles.includes(item));
    }
    return undefined;
  };

  const renderRolesFromList = roles => {
    return roles.map(role => (
      <MenuItem key={role} value={role}>
        {rolesTranslated.find(roleTranslation => roleTranslation.key === role)?.value ?? role}
      </MenuItem>
    ));
  };

  const renderRoleSubheader = (key, content) => <ListSubheader key={key}>{content}</ListSubheader>;

  const renderRoles = () => {
    const otherRoles = getOtherRoles(collaboratorsRecommendedRoles);

    if (collaboratorsRecommendedRoles?.length > 0 && otherRoles?.length > 0) {
      const recommendedRolesRendered = [
        renderRoleSubheader("subheader_recommended", "Häufig verwendet"),
        renderRolesFromList(collaboratorsRecommendedRoles),
      ];

      const otherRolesRendered = [renderRoleSubheader("subheader_others", "Weitere"), renderRolesFromList(otherRoles)];
      return [...recommendedRolesRendered, ...otherRolesRendered];
    }
    return [renderRolesFromList(roles)];
  };

  return (
    <CollaboratorContainer>
      <ButtonContainer>
        <ButtonGroup color="primary" size={"small"} aria-label="outlined primary button group">
          <Button
            startIcon={<PersonIcon />}
            variant={collaboratorMode === "Person" ? "contained" : "outlined"}
            onClick={() => updateCollaborator({ ...collaborator, mode: "Person" })}
            size={"small"}
          >
            {"Person"}
          </Button>
          <Button
            startIcon={<BusinessIcon />}
            variant={collaboratorMode === "Organisation" ? "contained" : "outlined"}
            onClick={() => updateCollaborator({ ...collaborator, mode: "Organisation" })}
            size={"small"}
          >
            {"Organisation"}
          </Button>
        </ButtonGroup>
        <ControlIconsContainer>
          <IconButton onClick={moveUp}>
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton onClick={moveDown}>
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton onClick={removeSelf}>
            <DeleteIcon />
          </IconButton>
        </ControlIconsContainer>
      </ButtonContainer>

      <FieldsContainer>
        {collaboratorMode === "Person" && (
          <>
            <InputContainer>
              <TextField
                label="Vorname"
                value={given}
                onChange={e => updateCollaborator({ ...collaborator, given: e.target.value })}
                fullWidth
                margin="normal"
                size={"small"}
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label="Nachname"
                value={family}
                onChange={e =>
                  updateCollaborator({
                    ...collaborator,
                    family: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                size={"small"}
              />
            </InputContainer>
          </>
        )}
        {collaboratorMode === "Organisation" && (
          <InputContainer>
            <TextField
              label="Organisation"
              value={organization}
              onChange={e =>
                updateCollaborator({
                  ...collaborator,
                  organization: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              size={"small"}
            />
          </InputContainer>
        )}
        <RoleInputContainer>
          <div style={{}}>
            <FormControl fullWidth margin="normal">
              <SelectInput
                value={role}
                onChange={e => updateCollaborator({ ...collaborator, role: e.target.value })}
                size={"small"}
              >
                {renderRoles()}
              </SelectInput>
            </FormControl>
          </div>
        </RoleInputContainer>
      </FieldsContainer>
    </CollaboratorContainer>
  );
};

export const CollaboratorFields = memo(CollaboratorFieldsInternal);
