import { dateFields } from "../config/schemaPropertiesGrouping";

const translateDate = viewDate => {
  if (!viewDate) return {};

  const { year, month, day } = viewDate;
  const dates = [year, month, day].filter(Boolean);
  if (dates.length > 0) {
    return {
      "date-parts": [dates],
    };
  }
  return undefined;
};

const getDatesPart = view => {
  const translated = {};
  dateFields.forEach(field => {
    if (view[field]) {
      translated[field] = translateDate(view[field]);
    }
  });
  return translated;
};

const getCollaboratorsPart = view => {
  let metadata = {};
  if (view.collaborators) {
    view.collaborators.forEach(collaborator => {
      if (!metadata[collaborator.role]) {
        metadata[collaborator.role] = [];
      }
      let metaCollaborator = {
        family: collaborator.family,
        given: collaborator.given,
        literal: collaborator.organization,
      };
      if (metaCollaborator.family || metaCollaborator.given || metaCollaborator.literal) {
        metadata[collaborator.role].push(metaCollaborator);
      }
    });
  }

  return metadata;
};

const translatePageInfo = view => {
  const rangeParts = view?.page?.split("-");
  if (!rangeParts || view.length === 0) {
    return {};
  }
  return {
    page: view.page,
    "page-first": rangeParts[0],
  };
};

export const translateViewToMetadata = view => {
  try {
    const pageInfo = translatePageInfo(view);

    const result = {
      ...view,
      ...getDatesPart(view),
      ...getCollaboratorsPart(view),
      page: pageInfo.page,
      "page-first": pageInfo["page-first"],
    };
    delete result["collaborators"];
    delete result["mediaType"];

    return result;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
