import { useEffect } from "react";

export const useCitationStyle = (availableStyles, style, setStyle) => {

  useEffect(() => {
    const getQueryParam = param => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get(param);
    };

    if (availableStyles?.length > 0) {
      const citationStyleFromQuery = getQueryParam("citationStyle");

      const citationStyleId = citationStyleFromQuery
        ? availableStyles.find(style => style.id === citationStyleFromQuery)?.id
        : style;

      if (citationStyleId) {
        setStyle(citationStyleId);
      } else {
        setStyle(availableStyles[0].id);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableStyles]);

};
