import { Button, ButtonGroup, styled } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import { BOOKS, SCIENTIFIC_LITERATURE } from "../../hooks/useMainLayout";
import BookIcon from "@mui/icons-material/Book";
import React from "react";

const ResultsTypeButton = styled(Button)({
  fontSize: "0.7rem",
});

const ResultsTypeContainer = styled("div")({
  padding: "20px 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

export const ResultsType = ({ resultType, setResultType }) => {
  return (
    <ResultsTypeContainer>
      <ButtonGroup color="primary" size={"small"} aria-label="outlined primary button group">
        <ResultsTypeButton
          startIcon={<SchoolIcon />}
          variant={resultType === SCIENTIFIC_LITERATURE ? "contained" : "outlined"}
          onClick={() => setResultType(SCIENTIFIC_LITERATURE)}
          size={"small"}
        >
          {"Wissenschaftliche Literatur"}
        </ResultsTypeButton>
        <ResultsTypeButton
          startIcon={<BookIcon />}
          variant={resultType === BOOKS ? "contained" : "outlined"}
          onClick={() => setResultType(BOOKS)}
          size={"small"}
        >
          {"Bücher"}
        </ResultsTypeButton>
      </ButtonGroup>
    </ResultsTypeContainer>
  );
};
