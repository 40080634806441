import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { debounce, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { BOOKS, SCIENTIFIC_LITERATURE, useMainLayout } from "../../hooks/useMainLayout";
import { CitationsContainer } from "../citationList/CitationsContainer";
import { BibliographyLoading } from "./BibliographyLoading";
import { ResultsType } from "./ResultsType";
import { CopyBibliographyButton } from "./bibliographyHeader/CopyBibliographyButton";
import { ManualCitationButton } from "./bibliographyHeader/ManualCitationButton";
import { MediaTypeFilter } from "./bibliographyHeader/MediaTypeFilter";
import { Sorting } from "./bibliographyHeader/Sorting";
import { SearchResults } from "./searchResults/SearchResults";

const SearchContainerFullWidth = styled("div")(({ theme }) => ({
  width: "100%",
}));

const SearchContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const CitationStyleTypography = styled(Typography)(({ theme }) => ({
  color: "grey",
  cursor: "pointer",
  marginLeft: ".5rem",
  textAlign: "left",
  marginBottom: "15px",
  "&:hover": {
    color: "lightblue",
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    marginBottom: "25px",
  },
}));

const BibliographyHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

const DataManagementHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

const CitationOutputContainer = styled(Box)({
  flexGrow: 1,
  marginTop: ".5rem",
  width: "100%",
});

const SearchTextField = styled(TextField)({
  width: "100%",
});

const NoEntries = styled(Typography)({
  color: "grey",
  margin: " 20px auto",
  textAlign: "center",
});

export const MainLayout = ({
  setShowPreferences,
  setNewManualCitationOpen,
  onCitationEdit,
  copyInTextCitation,
  copyBibliographyEntry,
  style,
  data,
  setData,
  setError,
  isBibliographyLoading,
  setBibliographyLoading,
  copyBibliography,
  sortOption,
  setSortOption,
  language,
  selectedMediaTypes,
  setSelectedMediaTypes,
  resetMediaTypes,
  resultType,
  setResultType,
  availableCitationStyles,
}) => {
  const {
    query,
    setQuery,
    scientificLiteratureResults,
    setScientificLiteratureResults,
    bookResults,
    setBookResults,
    directFetching,
    loading,
    handleInputConfirmation,
    handleSortChange,
    onDelete,
    handleMediaTypeChange,
    createCitationFromScientificLiteratureEntry,
    createCitationFromDirectFetching,
    createCitationFromGoogleBook,
  } = useMainLayout(
    resultType,
    setResultType,
    data,
    setData,
    sortOption,
    setSortOption,
    style,
    language,
    resetMediaTypes,
    setError,
    setBibliographyLoading,
    setSelectedMediaTypes
  );

  const [showLoading, setShowLoading] = useState(false);
  const [queryInternal, setQueryInternal] = useState("");
  const [triggerQueryRefresh, setTriggerQueryRefresh] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce(queryInternal => {
        setQuery(queryInternal ? queryInternal.trim() : "");
        setTriggerQueryRefresh(trigger => !trigger);
      }, 500),
    [setQuery]
  );

  useEffect(() => {
    if (!isEmpty(query)) {
      handleInputConfirmation();
    } else {
      setScientificLiteratureResults([]);
      setBookResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, triggerQueryRefresh, setBookResults, setScientificLiteratureResults]);

  useEffect(() => {
    const refreshBooks = resultType === BOOKS && bookResults.length === 0 && !isEmpty(query);
    const refreshScientificLiterature =
      resultType === SCIENTIFIC_LITERATURE && scientificLiteratureResults.length === 0 && !isEmpty(query);
    if (refreshBooks || refreshScientificLiterature) {
      handleInputConfirmation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultType]);

  useEffect(() => {
    if (isBibliographyLoading) {
      setShowLoading(true);
    } else {
      const timer = setTimeout(() => {
        setShowLoading(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isBibliographyLoading]);

  const onSearch = event => {
    setQueryInternal(event.target.value);
    debouncedSearch.cancel();
    debouncedSearch(event.target.value);
  };

  const renderCitationOutput = () => {
    if (data?.length === 0) {
      return (
        <>
          <Divider />
          <BibliographyLoading showLoading={showLoading} />
          <div style={{ width: "100%" }}>
            <NoEntries variant="subtitle1">{"Noch keine Einträge..."}</NoEntries>
          </div>
        </>
      );
    }

    return (
      <>
        <Divider />
        <BibliographyLoading showLoading={showLoading} />
        <BibliographyHeader>
          <CopyBibliographyButton copyBibliography={copyBibliography} />
          <DataManagementHeader>
            <Sorting sortOption={sortOption} handleSortChange={handleSortChange} isBibliographyLoading={isBibliographyLoading} />
            <MediaTypeFilter data={data} selectedMediaTypes={selectedMediaTypes} handleMediaTypeChange={handleMediaTypeChange} />
          </DataManagementHeader>
        </BibliographyHeader>
        <CitationsContainer
          data={data}
          onEdit={onCitationEdit}
          onDelete={onDelete}
          copyInTextCitation={copyInTextCitation}
          copyBibliographyEntry={copyBibliographyEntry}
        />
      </>
    );
  };

  return (
    <>
      <CitationStyleTypography variant="caption" onClick={() => setShowPreferences(true)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {availableCitationStyles.find(styleInfo => styleInfo.id === style)?.label ?? style}
          <ArrowDropDownIcon />
        </div>
      </CitationStyleTypography>
      <ResultsType resultType={resultType} setResultType={setResultType} />
      <SearchContainerFullWidth>
        <SearchContainer>
          <SearchTextField
            label="Suchbegriff"
            variant="outlined"
            placeholder={"Nach Titel, URL, DOI, ISBN oder ISSN suchen"}
            value={queryInternal}
            onChange={onSearch}
          />
          <SearchResults
            loading={loading}
            scientificLiteratureResults={scientificLiteratureResults}
            directFetching={directFetching}
            resultType={resultType}
            bookResults={bookResults}
            setScientificLiteratureResults={setScientificLiteratureResults}
            createCitationFromDirectFetching={createCitationFromDirectFetching}
            createCitationFromScientificLiteratureEntry={createCitationFromScientificLiteratureEntry}
            createCitationFromGoogleBook={createCitationFromGoogleBook}
            setBookResults={setBookResults}
          />
        </SearchContainer>
      </SearchContainerFullWidth>
      <ManualCitationButton setNewManualCitationOpen={setNewManualCitationOpen} />
      <CitationOutputContainer style={{}}>{renderCitationOutput()}</CitationOutputContainer>
    </>
  );
};
