import { doi } from "doi-utils";
import parseIsbn from "isbn3/lib/parse";
import { useCallback, useState } from "react";
import isURL from "validator/lib/isURL";
import { getBooksOptionsByKeyword, getScientificLiteratureOptionsByKeyword } from "../logic/citationApi";
import {
  createNewEntryFromDoi,
  createNewEntryFromGoogleSelfLink,
  createNewEntryFromWeb,
  deleteEntry,
  findSingleMetadataByDoi,
  findSingleMetadataByIsbn,
  findSingleMetadataByUrl,
  updateCitationOutput,
} from "../logic/citationGeneration";
import { sortData } from "../logic/dataSorting";

export const SCIENTIFIC_LITERATURE = "SCIENTIFIC_LITERATURE";
export const BOOKS = "BOOKS";

export const useMainLayout = (
  resultType,
  setResultType,
  data,
  setData,
  sortOption,
  setSortOption,
  style,
  language,
  resetMediaTypes,
  setError,
  setBibliographyLoading,
  setSelectedMediaTypes,
) => {
  const [query, setQuery] = useState("");
  const [scientificLiteratureResults, setScientificLiteratureResults] = useState([]);
  const [bookResults, setBookResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [directFetching, setDirectFetching] = useState(false);

  const handleSortChange = event => {
    const selectedOption = event.target.value;
    setSortOption(selectedOption);
    const sortedData = sortData(selectedOption, data);
    updateCitationOutput(sortedData, style, selectedOption, language, setData, setError, setBibliographyLoading);
  };

  const onDelete = useCallback(
    id => deleteEntry(data, id, style, sortOption, language, setData, setError, setBibliographyLoading),
    [style, data, sortOption, language, setData, setError, setBibliographyLoading]
  );

  const handleMediaTypeChange = event => {
    const types = event.target.value;
    setSelectedMediaTypes(types);

    const newData = data.map(item => {
      if (types.length > 0 && !types.includes(item.view.mediaType)) {
        return { ...item, hidden: true };
      } else {
        const { hidden, ...rest } = item;
        return rest;
      }
    });

    updateCitationOutput(newData, style, sortOption, language, setData, setError, setBibliographyLoading);
  };

  const handleSearchFromOpenAlex = async () => {
    setLoading(true);
    try {
      const data = await getScientificLiteratureOptionsByKeyword(query);
      setDirectFetching(false);
      setScientificLiteratureResults(data);
      setBookResults([]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchFromGoogleBooks = async () => {
    setLoading(true);
    try {
      const data = await getBooksOptionsByKeyword(query);
      setDirectFetching(false);
      setBookResults(data);
      setScientificLiteratureResults([]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const isValidUrl = urlString => {
    return isURL(urlString, { require_protocol: false });
  };

  const handleInputConfirmation = () => {
    if (parseIsbn(query)?.isValid) {
      setLoading(true);
      findSingleMetadataByIsbn(query)
        .then(data => {
          setLoading(false);
          setDirectFetching(true);
          setScientificLiteratureResults([data]);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (doi.validate(query)) {
      setLoading(true);
      findSingleMetadataByDoi(doi.normalize(query))
        .then(data => {
          setLoading(false);
          setDirectFetching(true);
          setScientificLiteratureResults([data]);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (isValidUrl(query)) {
      setLoading(true);
      findSingleMetadataByUrl(query)
        .then(data => {
          setLoading(false);
          setDirectFetching(true);
          setScientificLiteratureResults([data]);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      if (resultType === SCIENTIFIC_LITERATURE) {
        handleSearchFromOpenAlex();
      } else {
        handleSearchFromGoogleBooks();
      }
    }
  };

  const createCitationFromScientificLiteratureEntry = item => {
    const doiValue = item.doi;
    createNewEntryFromDoi(
      resetMediaTypes(data),
      doiValue,
      style,
      sortOption,
      language,
      setData,
      setError,
      setBibliographyLoading
    );
    setScientificLiteratureResults([]);
    setBookResults([]);
  };

  const createCitationFromDirectFetching = item => {
    createNewEntryFromWeb(
      resetMediaTypes(data),
      () => item,
      style,
      sortOption,
      language,
      setData,
      setError,
      setBibliographyLoading
    );
    setScientificLiteratureResults([]);
    setBookResults([]);
  };

  const createCitationFromGoogleBook = item => {
    createNewEntryFromGoogleSelfLink(
      resetMediaTypes(data),
      item?.selfLink,
      style,
      sortOption,
      language,
      setData,
      setError,
      setBibliographyLoading
    );
    setScientificLiteratureResults([]);
    setBookResults([]);
  };

  return {
    query,
    setQuery,
    scientificLiteratureResults,
    setScientificLiteratureResults,
    bookResults,
    resultType,
    setResultType,
    setBookResults,
    directFetching,
    loading,
    setLoading,
    handleInputConfirmation,
    handleSearchFromOpenAlex,
    handleSortChange,
    onDelete,
    handleMediaTypeChange,
    createCitationFromScientificLiteratureEntry,
    createCitationFromDirectFetching,
    createCitationFromGoogleBook
  };
};
