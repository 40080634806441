import { isEmpty } from "lodash";

const translateMediaType = type => {
  if (type === "article") {
    return "Artikel";
  }

  if (type === "book") {
    return "Buch";
  }
  return undefined;
};

const getAuthorInfo = item => {
  return (item?.authorships ?? [])
    .map(authorship => {
      const author = authorship?.author?.display_name;
      const institution = (authorship?.institutions ?? [])
        .map(institution => institution?.display_name)
        .filter(it => it)
        .join(", ");

      if (!isEmpty(author) && !isEmpty(institution)) {
        return `${author} (${institution})`;
      }
      if (!isEmpty(author)) {
        return author;
      }
      if (!isEmpty(institution)) {
        return author;
      }
      return undefined;
    })
    .filter(it => it)
    .join(" · ");
};

export const getScientificLiteratureItemDetails = item => {
  return [translateMediaType(item.type), item?.publication_year, getAuthorInfo(item)].filter(it => it).join(" · ");
};
