import Container from "@mui/material/Container";
import React from "react";
import { manualViewsConfig } from "../../config/manualViews";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const PopularMediaTypePaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 150,
  height: 100,
  margin: theme.spacing(0.8),
  padding: theme.spacing(1.5),
  backgroundColor: "#f5f5f5",
  border: `1px solid #f5f5f5`,
  cursor: "pointer",
  transition: "color 0.3s ease",
  color: "#949494",
  "&:hover": {
    color: "#9a9a9a",
    border: `1px solid #c7c7c7`,
    boxShadow: "0 0 4px 4px rgba(152,152,152,0.2)",
  },
  "&:active": {
    color: "#9a9a9a",
    backgroundColor: "#efefef",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const MediaTypePaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  margin: theme.spacing(1),
  padding: theme.spacing(1.5),
  backgroundColor: "#f5f5f5",
  cursor: "pointer",
  transition: "color 0.3s ease",
  border: `2px solid #f5f5f5`,
  color: "#949494",
  "&:hover": {
    color: "#9a9a9a",
    border: `2px solid #c7c7c7`,
    boxShadow: "0 0 4px 4px rgba(152,152,152,0.2)",
  },
  "&:active": {
    color: "#9a9a9a",
    backgroundColor: "#efefef",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const GoBackButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginTop: `25px`,
});

const GoBackButton = styled(Button)({
  margin: "0 10px 0 10px",
});

const CenteredTypography = styled(Typography)({
  color: "grey",
  textAlign: "center",
});

const CenteredDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  margin: theme.spacing(1),
  width: "100%",
}));

const SubtitleTypography = styled(Typography)({
  color: "#7a7a7a",
  fontWeight: "bold",
});

const CaptionTypography = styled(Typography)({
  color: "black",
});

export const CitationTypeChoice = ({ onCitationSelect, goBack }) => {
  const config = manualViewsConfig;

  const renderPopularMediaTypes = () => {
    return config
      .filter(typeConfig => typeConfig.popularType && !typeConfig.hidden)
      .map(typeConfig => (
        <PopularMediaTypePaper onClick={() => onCitationSelect(typeConfig.mediaType)} key={typeConfig?.mediaType} elevation={3}>
          <CenteredTypography variant="subtitle1">{typeConfig?.mediaType}</CenteredTypography>
        </PopularMediaTypePaper>
      ));
  };

  const renderAllMediaTypes = () => {
    return config
      .filter(typeConfig => !typeConfig.hidden)
      .sort((a, b) => a?.mediaType?.localeCompare(b?.mediaType))
      .map(typeConfig => (
        <MediaTypePaper key={typeConfig?.mediaType} onClick={() => onCitationSelect(typeConfig?.mediaType)} elevation={1}>
          <div>
            <SubtitleTypography variant="subtitle2">{typeConfig?.mediaType}</SubtitleTypography>
          </div>
          <div>
            <CaptionTypography variant="caption">{typeConfig?.description}</CaptionTypography>
          </div>
        </MediaTypePaper>
      ));
  };

  return (
    <Container>
      <CenteredTypography variant="h5" sx={{ margin: "1rem" }}>
        {"Die beliebtesten Medientypen"}
      </CenteredTypography>
      <CenteredDiv>{renderPopularMediaTypes()}</CenteredDiv>
      <Divider />
      <CenteredTypography variant="h5" sx={{ margin: "2rem 0 1rem 1rem" }}>
        {"Alle Medientypen"}
      </CenteredTypography>
      <Stack spacing={1}>{renderAllMediaTypes()}</Stack>
      <GoBackButtonContainer>
        <GoBackButton variant="contained" color="secondary" onClick={goBack}>
          {"Zurück"}
        </GoBackButton>
      </GoBackButtonContainer>
    </Container>
  );
};
