import { List, styled } from "@mui/material";

export const ScrollableList = styled(List)({
  position: "absolute",
  top: "100%",
  width: "100%",
  zIndex: 10,
  maxHeight: "400px",
  overflowY: "auto",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
});
