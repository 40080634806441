import { styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
// @ts-ignore
import projectDetails from "../../package.json";

const FooterText = styled(Typography)({
  color: "#cfcfcf",
  fontSize: "9px",
  textTransform: "none",
});

const VersionFooter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  margin: "1rem 0 0 0",
});

const MainContainerInternal = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  padding: "3rem 3rem 0 3rem",
  boxShadow: "0 0 8px 8px rgba(152,152,152,0.15)",
  margin: "1rem 0",
  minWidth: "600px",
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    minWidth: "0",
    boxShadow: "none",
    padding: "20px 5px 20px 5px",
  },
}));

export const MainContainer = ({ children }) => {
  return (
    <MainContainerInternal>
      {children}
      <VersionFooter>
        <FooterText variant="overline">{`v.${projectDetails.version}`}</FooterText>
      </VersionFooter>
    </MainContainerInternal>
  );
};
