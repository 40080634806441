import { CircularProgress, ListItem, styled } from "@mui/material";
import React from "react";
import { ScrollableList } from "./ScrollableList";

const LoadingContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export const ResultsFetchLoading = () => {
  return (
    <ScrollableList>
      <ListItem key={"no-results"}>
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      </ListItem>
    </ScrollableList>
  );
};
