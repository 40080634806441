import React, { useCallback, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArticleIcon from "@mui/icons-material/Article";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const MenuItemText = styled("div")({
  marginLeft: `5px`,
});

export const SnowmanMenu = ({ copyBibliographyEntry, copyInTextCitation, handleEditCitation, handleRemoveCitation }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = action => () => {
    action();
    handleClose();
  };

  const closeOnScroll = useCallback(() => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  }, [anchorEl, setAnchorEl]);

  useEffect(() => {
    window.addEventListener("scroll", closeOnScroll);

    return () => {
      window.removeEventListener("scroll", closeOnScroll);
    };
  }, [closeOnScroll]);

  const renderMenu = () => {
    return (
      <>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock={true}
        >
          <MenuItem onClick={handleMenuItemClick(copyBibliographyEntry)}>
            <ArticleIcon />
            <MenuItemText>{"Bibliographieeintrag kopieren"}</MenuItemText>
          </MenuItem>
          <MenuItem onClick={handleMenuItemClick(copyInTextCitation)}>
            <FormatQuoteIcon />
            <MenuItemText>{"Textzitat kopieren"}</MenuItemText>
          </MenuItem>
          <MenuItem onClick={handleMenuItemClick(handleEditCitation)}>
            <EditIcon />
            <MenuItemText>{"Editieren"}</MenuItemText>
          </MenuItem>
          <MenuItem onClick={handleMenuItemClick(handleRemoveCitation)}>
            <CloseIcon />
            <MenuItemText>{"Entfernen"}</MenuItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      {anchorEl && renderMenu()}
    </div>
  );
};
