import { List, ListItem, Typography, styled } from "@mui/material";
import React, { memo } from "react";
import { SnowmanMenu } from "./SnowmanMenu";

const Citation = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  wordBreak: "break-word",
});

const CitationListItem = styled(ListItem)({
  padding: "10px 0px 10px 10px",
});

const CitationList = styled(List)({
  width: "100%",
  overflowX: "hidden",
});

const CitationsContainerInternal = ({ data, onEdit, onDelete, copyInTextCitation, copyBibliographyEntry }) => {
  const handleRemoveCitation = id => {
    onDelete(id);
  };

  const getCitationById = id => {
    return data.find(citation => citation.id === id)?.view;
  };

  const handleEditCitation = id => {
    const citationToEdit = getCitationById(id);
    onEdit(citationToEdit);
  };

  const renderContent = () =>
    (data ?? [])
      .filter(item => !item?.hidden)
      .map(entry => (
        <CitationListItem key={entry.id}>
          <Citation>
            <div>
              <div dangerouslySetInnerHTML={{ __html: entry.bibliographyHtmlEntry }} />
              <Typography variant="caption" style={{ color: "#b3b3b3" }}>
                {entry?.view?.mediaType}
              </Typography>
            </div>
            <SnowmanMenu
              copyBibliographyEntry={() => copyBibliographyEntry(entry.bibliographyTextEntry, entry.bibliographyHtmlEntry)}
              copyInTextCitation={() => copyInTextCitation(entry.inTextCitationText, entry.inTextCitationHtml)}
              handleEditCitation={() => handleEditCitation(entry.id)}
              handleRemoveCitation={() => handleRemoveCitation(entry.id)}
            />
          </Citation>
        </CitationListItem>
      ));

  return <CitationList>{renderContent()}</CitationList>;
};

export const CitationsContainer = memo(CitationsContainerInternal);
