import AddIcon from "@mui/icons-material/Add";
import { Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback } from "react";
import { CollaboratorFields } from "./CollaboratorFields";

const StyledLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  marginBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
  margin: ".5rem 1rem",
}));

export const CitationCollaborators = ({ value, setFormData, collaboratorsRecommendedRoles }) => {
  const updateCollaborator = useCallback(
    (index, updatedData) =>
      setFormData(formData => ({
        ...formData,
        collaborators: (formData.collaborators ?? []).map((item, idx) => (idx === index ? updatedData : item)),
      })),
    [setFormData]
  );

  const addCollaborator = useCallback(() => {
    setFormData(formData => ({
      ...formData,
      collaborators: [
        ...(formData.collaborators ?? []),
        { mode: "Person", family: "", given: "", role: collaboratorsRecommendedRoles?.[0] ?? "author" },
      ],
    }));
  }, [setFormData, collaboratorsRecommendedRoles]);

  const removeCollaborator = useCallback(
    index => {
      setFormData(formData => ({
        ...formData,
        collaborators: [...(formData.collaborators ?? []).filter((_, idx) => idx !== index)],
      }));
    },
    [setFormData]
  );

  const moveCollaboratorUp = useCallback(
    index => {
      setFormData(formData => {
        if (index === 0) {
          return formData;
        }

        const newValue = [...(formData.collaborators ?? [])];
        const temp = newValue[index];
        newValue[index] = newValue[index - 1];
        newValue[index - 1] = temp;
        return { ...formData, collaborators: newValue };
      });
    },
    [setFormData]
  );

  const moveCollaboratorDown = useCallback(
    index => {
      setFormData(formData => {
        if (index === (formData.collaborators?.length ?? 0) - 1) {
          return formData;
        }

        const newValue = [...(formData.collaborators ?? [])];
        const temp = newValue[index];
        newValue[index] = newValue[index + 1];
        newValue[index + 1] = temp;
        return { ...formData, collaborators: newValue };
      });
    },
    [setFormData]
  );

  return (
    <div style={{ margin: "2rem 0" }}>
      <Divider />
      <StyledLabel>{"Mitarbeiter"}</StyledLabel>
      {value.map((collaborator, idx) => (
        <CollaboratorFields
          key={idx}
          index={idx}
          collaborator={collaborator}
          updateCollaboratorCallback={updateCollaborator}
          removeSelfCallback={removeCollaborator}
          moveCollaboratorUpCallback={moveCollaboratorUp}
          moveCollaboratorDownCallback={moveCollaboratorDown}
          collaboratorsRecommendedRoles={collaboratorsRecommendedRoles}
        />
      ))}
      <div style={{ margin: "1rem" }}>
        <Button startIcon={<AddIcon />} onClick={addCollaborator}>
          {"Mitarbeiter hinzufügen"}
        </Button>
      </div>
      <Divider />
    </div>
  );
};
