import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import React from "react";

export const ExtendedFields = ({ showAdvanced, setShowAdvanced, mounted, renderAdvancedInputs, moveScroll }) => {
  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const close = () => {
    setShowAdvanced(false);
    moveScroll();
  };

  return (
    <div style={{ margin: "2rem 0" }}>
      <Accordion
        expanded={showAdvanced}
        onChange={toggleAdvanced}
        style={{
          boxShadow: "0 0 8px 4px rgba(152,152,152,0.2)",
          borderRadius: "8px",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {showAdvanced ? "Erweiterte Eigenschaften verstecken" : "Erweiterte Eigenschaften anzeigen"}
        </AccordionSummary>
        <AccordionDetails>{mounted && renderAdvancedInputs()}</AccordionDetails>
      </Accordion>
      {showAdvanced && (
        <Button onClick={close} style={{ margin: "1rem 0" }} startIcon={<ExpandLessIcon />}>
          {"Erweiterte Eigenschaften verstecken"}
        </Button>
      )}
    </div>
  );
};
