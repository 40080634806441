import { endpoints } from "../endpoints";

const encodeParam = param => {
  return encodeURIComponent(param);
};

const getMetadataByGoogleLink = async googleLink => {
  try {
    const encodedGoogleLink = encodeParam(googleLink);
    const response = await fetch(`${endpoints.citationBackend}/book?googleLink=${encodedGoogleLink}`);

    if (!response.ok) {
      throw new Error("Eintrag mit dem angegebenen Google Link konnte nicht gefunden werden.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error;
  }
};

const getMetadataByDoi = async doi => {
  try {
    const encodedDoi = encodeParam(doi);
    const response = await fetch(`${endpoints.citationBackend}/doi?id=${encodedDoi}`);

    if (!response.ok) {
      throw new Error("Eintrag mit dem angegebenen DOI konnte nicht gefunden werden.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error;
  }
};

const getMetadataByIsbn = async isbn => {
  try {
    const encodedIsbn = encodeParam(isbn);
    const response = await fetch(`${endpoints.citationBackend}/isbn/${encodedIsbn}`);

    if (!response.ok) {
      throw new Error("Eintrag mit dem angegebenen ISBN konnte nicht gefunden werden.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error;
  }
};

const getMetadataByUrl = async url => {
  try {
    const encodedUrl = encodeParam(url);
    const response = await fetch(`${endpoints.citationBackend}/website?url=${encodedUrl}`);

    if (!response.ok) {
      throw new Error("Eintrag mit dem angegebenen URL konnte nicht gefunden werden.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error;
  }
};

const getScientificLiteratureOptionsByKeyword = async keyword => {
  try {
    const encodedUrl = encodeParam(keyword);
    const response = await fetch(`${endpoints.citationBackend}/search/scientific-literature?keyword=${encodedUrl}`);

    if (!response.ok) {
      throw new Error("Eintrag mit dem angegebenen Suchbegriff konnte nicht gefunden werden.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error;
  }
};

const getBooksOptionsByKeyword = async keyword => {
  try {
    const encodedUrl = encodeParam(keyword);
    const response = await fetch(`${endpoints.citationBackend}/search/books?keyword=${encodedUrl}`);

    if (!response.ok) {
      throw new Error("Eintrag mit dem angegebenen Suchbegriff konnte nicht gefunden werden.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error;
  }
};

const createBibliographyAndCitation = async (metadataItems, style, sortOption, language) => {
  try {
    const response = await fetch(`${endpoints.citationBackend}/bibliography`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ metadataItems, style, defaultSorting: sortOption === "DEFAULT", language }),
    });
    if (!response.ok) {
      throw new Error("Fehler bei der Erstellung der Bibliographie aufgetreten.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error;
  }
};

const getAvailableStyles = async () => {
  try {
    const response = await fetch(`${endpoints.citationBackend}/styles`);

    if (!response.ok) {
      throw new Error("Zitationsstile konnten nicht geladen werden.");
    }
    return await response.json();
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error;
  }
};

export {
  getMetadataByDoi,
  getMetadataByIsbn,
  getMetadataByUrl,
  createBibliographyAndCitation,
  getMetadataByGoogleLink,
  getScientificLiteratureOptionsByKeyword,
  getBooksOptionsByKeyword,
  getAvailableStyles,
};
