import { translateMetadataToView } from "../translator/metadataToViewTranslator";
import { translateViewToMetadata } from "../translator/viewToMetadataTranslator";
import {
  createBibliographyAndCitation,
  getMetadataByDoi,
  getMetadataByGoogleLink,
  getMetadataByIsbn,
  getMetadataByUrl,
} from "./citationApi";
import { sortData } from "./dataSorting";

const mergeOutputWithData = (data, output) => {
  const newOutput = output.map(outputEntry => updateDataItem(data, outputEntry));
  data.forEach(dataEntry => {
    if (!newOutput.find(outputEntry => outputEntry.id === dataEntry.id)) {
      newOutput.push(dataEntry);
    }
  });
  return newOutput;
};

const findMetadataByGoogleSelfLink = async googleSelfLink => {
  const metadata = await getMetadataByGoogleLink(googleSelfLink);
  const view = translateMetadataToView(metadata);

  return {
    id: metadata.id,
    createdOn: new Date(),
    metadata: translateViewToMetadata(view),
    view,
  };
};

const findSingleMetadataByDoi = async resourceId => {
  const metadata = await getMetadataByDoi(resourceId);
  const view = translateMetadataToView(metadata);

  return {
    id: metadata.id,
    createdOn: new Date(),
    metadata: translateViewToMetadata(view),
    view,
  };
};

const findSingleMetadataByIsbn = async resourceId => {
  const metadata = await getMetadataByIsbn(resourceId);
  const view = translateMetadataToView(metadata);

  return {
    id: metadata.id,
    createdOn: new Date(),
    metadata: translateViewToMetadata(view),
    view,
  };
};

const findSingleMetadataByUrl = async url => {
  const metadata = await getMetadataByUrl(url);
  const view = translateMetadataToView(metadata);
  return {
    id: metadata.id,
    createdOn: new Date(),
    metadata: translateViewToMetadata(view),
    view,
  };
};

const getMetadataItems = data => {
  return data.filter(item => !item?.hidden).map(item => item.metadata);
};

const updateDataItem = (data, outputEntry) => {
  const dataEntry = data.find(dataEntry => dataEntry.id === outputEntry.id);
  return {
    ...dataEntry,
    bibliographyTextEntry: outputEntry?.bibliographyTextEntry,
    bibliographyHtmlEntry: outputEntry?.bibliographyHtmlEntry,
    inTextCitationText: outputEntry?.inTextCitationText,
    inTextCitationHtml: outputEntry?.inTextCitationHtml,
  };
};

const createNewEntryFromGoogleSelfLink = async (
  data,
  googleSelfLink,
  style,
  sortOption,
  language,
  setData,
  setError,
  setLoading
) => {
  return await createNewEntryFromWeb(
    data,
    () => findMetadataByGoogleSelfLink(googleSelfLink),
    style,
    sortOption,
    language,
    setData,
    setError,
    setLoading
  );
};

const createNewEntryFromDoi = async (data, doi, style, sortOption, language, setData, setError, setLoading) => {
  return await createNewEntryFromWeb(
    data,
    () => findSingleMetadataByDoi(doi),
    style,
    sortOption,
    language,
    setData,
    setError,
    setLoading
  );
};

const createNewEntryFromIsbn = async (data, isbn, style, sortOption, language, setData, setError, setLoading) => {
  return await createNewEntryFromWeb(
    data,
    () => findSingleMetadataByIsbn(isbn),
    style,
    sortOption,
    language,
    setData,
    setError,
    setLoading
  );
};

const createNewEntryFromWeb = async (data, getMetadata, style, sortOption, language, setData, setError, setLoading) => {
  setLoading(true);
  let newEntry;
  try {
    newEntry = await getMetadata();
  } catch (error) {
    setError(error.message);
    setLoading(false);
    return;
  }

  const newData = sortData(sortOption, [...data, newEntry]);

  try {
    const output = await createBibliographyAndCitation(getMetadataItems(newData), style, sortOption, language);
    setData(mergeOutputWithData(newData, output));
    setLoading(false);
  } catch (error) {
    setError(error.message);
    setLoading(false);
  }
};

const createNewManualEntry = async (data, view, style, sortOption, language, setData, setError, setLoading) => {
  setLoading(true);
  const metadata = translateViewToMetadata(view);
  const newData = sortData(sortOption, [
    ...data,
    {
      id: metadata.id,
      metadata,
      view,
      createdOn: new Date(),
    },
  ]);

  try {
    const output = await createBibliographyAndCitation(getMetadataItems(newData), style, sortOption, language);
    setData(mergeOutputWithData(newData, output));
    setLoading(false);
  } catch (error) {
    setError(error.message);
    setLoading(false);
  }
};

const editEntry = async (data, view, style, sortOption, language, setData, setError, setLoading) => {
  setLoading(true);
  const metadata = translateViewToMetadata(view);
  const newData = sortData(
    sortOption,
    data.map(entry => {
      if (entry.id === metadata.id) {
        return { id: metadata.id, metadata, view, createdOn: entry.createdOn };
      } else {
        return entry;
      }
    })
  );

  try {
    const output = await createBibliographyAndCitation(getMetadataItems(newData), style, sortOption, language);
    setData(mergeOutputWithData(newData, output));
    setLoading(false);
  } catch (error) {
    setError(error.message);
    setLoading(false);
  }
};

const deleteEntry = async (data, resourceId, style, sortOption, language, setData, setError, setLoading) => {
  if (!data || data.length === 0) {
    return [];
  }
  setLoading(true);
  const newData = data.filter(entry => entry.id !== resourceId);
  try {
    const output = await createBibliographyAndCitation(getMetadataItems(newData), style, sortOption, language);
    setData(mergeOutputWithData(newData, output));
    setLoading(false);
  } catch (error) {
    setError(error.message);
    setLoading(false);
    setData(newData);
  }
};

const updateCitationOutput = async (data, style, sortOption, language, setData, setError, setLoading) => {
  if (!data || data.length === 0) {
    return;
  }
  try {
    setLoading(true);
    const output = await createBibliographyAndCitation(getMetadataItems(data), style, sortOption, language);
    setData(mergeOutputWithData(data, output));
    setLoading(false);
  } catch (error) {
    setError(error.message);
    setLoading(false);
  }
};

export {
  createNewEntryFromGoogleSelfLink,
  createNewEntryFromDoi,
  createNewEntryFromIsbn,
  createNewEntryFromWeb,
  createNewManualEntry,
  deleteEntry,
  editEntry,
  updateCitationOutput,
  findSingleMetadataByDoi,
  findSingleMetadataByIsbn,
  findSingleMetadataByUrl,
};
