import { numberOrStringFields, roles, stringFields } from "../config/schemaPropertiesGrouping";
import { manualViewsConfig } from "../config/manualViews";

const isIntegerValue = value => {
  if (typeof value === "number" && Number.isInteger(value)) {
    return true;
  }
  if (typeof value === "string" && /^\d+$/.test(value)) {
    return parseInt(value, 10);
  }
  return false;
};

const translateDate = metadataDate => {
  if (!metadataDate || !metadataDate["date-parts"] || metadataDate["date-parts"].length === 0) return {};

  const dateParts = metadataDate["date-parts"][0];

  const year = isIntegerValue(dateParts[0]) ? parseInt(dateParts[0], 10) : undefined;
  const month = isIntegerValue(dateParts[1]) ? parseInt(dateParts[1], 10) : undefined;
  const day = isIntegerValue(dateParts[2]) ? parseInt(dateParts[2], 10) : undefined;

  return { year, month, day };
};

const getDatesPart = metadata => {
  const dateFields = ["accessed", "available-date", "event-date", "issued", "original-date", "submitted"];
  const translated = {};

  dateFields.forEach(field => {
    if (metadata[field]) {
      translated[field] = translateDate(metadata[field]);
    }
  });
  return translated;
};

const aggregateAuthorName = author => {
  const given = author.given || "";
  const family = author.family || "";
  const droppingParticle = author["dropping-particle"] || "";
  const nonDroppingParticle = author["non-dropping-particle"] || "";
  const suffix = author.suffix || "";

  const givenOutput = `${suffix} ${given}`.replace(/\s+/g, " ").trim();
  const familyOutput = `${droppingParticle} ${nonDroppingParticle} ${family}`.replace(/\s+/g, " ").trim();

  return { given: givenOutput, family: familyOutput, literal: author.literal };
};

const getCollaboratorsPart = metadata => {
  let collaborators = [];

  roles.forEach(role => {
    if (metadata[role]) {
      metadata[role].forEach(collaborator => {
        const { given, family, literal } = aggregateAuthorName(collaborator);
        let viewCollaborator = {
          role,
        };
        if (literal) {
          viewCollaborator.organization = literal;
          collaborators.push(viewCollaborator);
        } else if (given || family) {
          viewCollaborator.given = given;
          viewCollaborator.family = family;
          collaborators.push(viewCollaborator);
        }
      });
    }
  });
  return { collaborators: collaborators };
};

const toString = value => {
  if (value === null || value === undefined) {
    return undefined;
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return undefined;
    } else {
      return String(value[0]);
    }
  }
  return String(value);
};

const parseCategories = metadata => {
  if (!metadata.categories || !Array.isArray(metadata.categories)) {
    return [];
  }
  return metadata.categories.map(category => String(category));
};

const simplePropsToMap = [
  "id",
  "type",
  "language",
  "short-title",
  "archive",
  "archive_collection",
  "archive_location",
  "archive-place",
  "authority",
  "call-number",
  "chapter-number",
  "collection-number",
  "collection-title",
  "container-title",
  "container-title-short",
  "dimensions",
  "division",
  "DOI",
  "edition",
  "event-title",
  "event-place",
  "first-reference-note-number",
  "genre",
  "ISBN",
  "ISSN",
  "issue",
  "jurisdiction",
  "keyword",
  "locator",
  "medium",
  "note",
  "number",
  "number-of-pages",
  "number-of-volumes",
  "original-publisher",
  "original-publisher-place",
  "original-title",
  "page",
  "part",
  "part-title",
  "printing",
  "publisher",
  "publisher-place",
  "references",
  "reviewed-genre",
  "reviewed-title",
  "scale",
  "section",
  "source",
  "status",
  "supplement",
  "title",
  "title-short",
  "URL",
  "version",
  "volume",
  "volume-title",
  "volume-title-short",
  "year-suffix",
];

export const translateMetadataToView = metadata => {
  try {
    const result = {
      ...getDatesPart(metadata),
      ...getCollaboratorsPart(metadata),
      categories: parseCategories(metadata),
      mediaType:
        manualViewsConfig.find(config => config.cslType === metadata?.type)?.mediaType ??
        manualViewsConfig.find(config => config?.fallback)?.mediaType,
    };

    simplePropsToMap.forEach(prop => {
      result[prop] = metadata[prop];
    });

    roles.forEach(role => {
      delete result[role];
    });

    [...numberOrStringFields, ...stringFields].forEach(prop => {
      result[prop] = toString(result[prop]);
    });

    return result;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
