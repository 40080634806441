import { Select } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

export const SelectInput = props => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const closeOnScroll = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open, setOpen]);

  useEffect(() => {
    window.addEventListener("scroll", closeOnScroll);

    return () => {
      window.removeEventListener("scroll", closeOnScroll);
    };
  }, [closeOnScroll]);

  return (
    <Select
      {...props}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      native={false}
      MenuProps={{ disableScrollLock: true }}
    />
  );
};
