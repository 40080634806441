import { ClickAwayListener, Link, ListItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { BOOKS, SCIENTIFIC_LITERATURE } from "../../../hooks/useMainLayout";
import { getBookItemDetails } from "../../../logic/detailsParsing/booksDetailsParsing";
import { getDirectlyFetchedItemDetails } from "../../../logic/detailsParsing/directFetchingDetailsParsing";
import { getScientificLiteratureItemDetails } from "../../../logic/detailsParsing/scientificLiteratureDetailsParsing";
import { ImageComponent } from "../ImageComponent";
import { ResultsFetchLoading } from "./ResultsFetchLoading";
import { ScrollableList } from "./ScrollableList";
import { SearchItemDetail } from "./SearchItemDetail";

const BookLink = styled(Link)({
  color: "grey",
  cursor: "pointer",
  textDecoration: "none",
  fontSize: "0.8rem",
  "&:hover": {
    color: "lightblue",
  },
});

const SearchResultListItem = styled(ListItem)({
  cursor: "pointer",
});

const BookSearchResultContent = styled("div")({
  width: "100%",
  display: "flex",
});

export const SearchResults = ({
  loading,
  directFetching,
  resultType,
  scientificLiteratureResults,
  setScientificLiteratureResults,
  bookResults,
  setBookResults,
  createCitationFromDirectFetching,
  createCitationFromScientificLiteratureEntry,
  createCitationFromGoogleBook,
}) => {
  const renderDirectFetchingResult = () => {
    return scientificLiteratureResults.map(item => (
      <SearchResultListItem
        key={item?.view?.DOI || item?.view?.ISBN || item?.view?.title}
        onClick={() => createCitationFromDirectFetching(item)}
      >
        <SearchItemDetail title={item?.view?.title} details={getDirectlyFetchedItemDetails(item)} />
      </SearchResultListItem>
    ));
  };

  const renderScientificLiteratureSearchResult = () => {
    return scientificLiteratureResults.map(item => (
      <SearchResultListItem
        key={item.doi ?? item?.display_name}
        onClick={() => createCitationFromScientificLiteratureEntry(item)}
      >
        <SearchItemDetail title={item.display_name} details={getScientificLiteratureItemDetails(item)} />
      </SearchResultListItem>
    ));
  };

  const renderBookSearchResult = () => {
    return bookResults.map(item => (
      <SearchResultListItem key={item.id} onClick={() => createCitationFromGoogleBook(item)}>
        <BookSearchResultContent>
          <ImageComponent
            imageUrl={item?.volumeInfo?.imageLinks?.smallThumbnail || item?.volumeInfo?.imageLinks?.smallThumbnail}
            description={item?.volumeInfo?.title}
          />
          <div>
            <SearchItemDetail title={item?.volumeInfo?.title} details={getBookItemDetails(item)} />
            {item?.volumeInfo?.previewLink && (
              <BookLink href={item?.volumeInfo?.previewLink} target="_blank" rel="noopener noreferrer">
                {"Auf Google Books anzeigen"}
              </BookLink>
            )}
          </div>
        </BookSearchResultContent>
      </SearchResultListItem>
    ));
  };

  if (loading) {
    return <ResultsFetchLoading />;
  }

  if (scientificLiteratureResults?.length > 0) {
    if (directFetching) {
      return (
        <ClickAwayListener onClickAway={() => setScientificLiteratureResults([])}>
          <ScrollableList>{renderDirectFetchingResult()}</ScrollableList>
        </ClickAwayListener>
      );
    }

    if (resultType === SCIENTIFIC_LITERATURE) {
      return (
        <ClickAwayListener onClickAway={() => setScientificLiteratureResults([])}>
          <ScrollableList>{renderScientificLiteratureSearchResult()}</ScrollableList>
        </ClickAwayListener>
      );
    }
  }

  if (resultType === BOOKS && bookResults?.length > 0) {
    return (
      <ClickAwayListener onClickAway={() => setBookResults([])}>
        <ScrollableList>{renderBookSearchResult()}</ScrollableList>
      </ClickAwayListener>
    );
  }

  return null;
};
