import React from "react";
import { InputLabel, MenuItem } from "@mui/material";
import { SelectInput } from "../../input/SelectInput";
import { SortOptions } from "../../../config/sortOptions";
import { HeaderSelectContainer } from "./HeaderSelectContainer";

export const Sorting = ({ sortOption, handleSortChange, isBibliographyLoading }) => {
  return (
    <HeaderSelectContainer size="small">
      <InputLabel id="sort-select-label">{"Sortieren nach"}</InputLabel>
      <SelectInput
        id="sort-select"
        name="sort-select"
        labelId="sort-select-label"
        value={sortOption}
        onChange={handleSortChange}
        label="Sortieren nach"
        disabled={isBibliographyLoading}
      >
        {Object.entries(SortOptions).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </SelectInput>
    </HeaderSelectContainer>
  );
};
