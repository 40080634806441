import ClearIcon from "@mui/icons-material/Clear";
import { FormControl, IconButton, InputLabel, MenuItem, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { SelectInput } from "../../input/SelectInput";

const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const StyledLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  marginBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
  margin: ".5rem 0",
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
  margin: ".5rem 0",
}));

const DatePartsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  alignItems: "center",
  justifyContent: "flex-start",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const PartsWithClearButton = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

const ClearButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "25px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "15px",
  },
}));

export const CitationDate = ({ fieldName, schema, value, setValue }) => {
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (value?.year && value?.month) {
      const numDays = getDaysInMonth(value.month, value.year);
      if (numDays) {
        setDays([...Array(numDays).keys()].map(i => i + 1));
      }
    } else {
      setDays([]);
    }
  }, [value]);

  const handleClearAll = () => {
    setValue({
      year: undefined,
      month: undefined,
      day: undefined,
    });
  };

  const getDayValue = () => {
    if (days && days.includes(value?.day)) {
      return value?.day ?? "";
    } else {
      return "";
    }
  };

  return (
    <div style={{ margin: "30px 0" }}>
      <StyledLabel>{schema?.title ?? fieldName}</StyledLabel>
      <PartsWithClearButton>
        <DatePartsContainer>
          <TextField
            id={`${fieldName}_year`}
            label="Jahr"
            type="number"
            value={value?.year ?? ""}
            onChange={e => setValue({ ...value, year: parseInt(e.target.value) })}
            variant="outlined"
            style={{ minWidth: "100px", maxWidth: "100px" }}
            size={"small"}
          />
          <div style={{ opacity: value?.year ? 1 : 0.5 }}>
            <FormControl variant="outlined" style={{ width: "100px" }} size={"small"}>
              <InputLabel id="month-select-label">{"Monat"}</InputLabel>
              <SelectInput
                id={`${fieldName}_month`}
                labelId="month-select-label"
                value={value?.month ?? ""}
                onChange={e => setValue({ ...value, month: e.target.value })}
                label="Monat"
                disabled={!value?.year}
                style={value?.year ? {} : { cursor: "not-allowed" }}
              >
                {[...Array(12).keys()].map(i => (
                  <MenuItem key={i} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </SelectInput>
            </FormControl>
          </div>
          <div style={{ opacity: value?.month ? 1 : 0.5 }}>
            <FormControl variant="outlined" style={{ width: "100px" }} size={"small"}>
              <InputLabel id="day-select-label">{"Tag"}</InputLabel>
              <SelectInput
                id={`${fieldName}_day`}
                labelId="day-select-label"
                value={getDayValue()}
                onChange={e => setValue({ ...value, day: e.target.value })}
                label="Tag"
                disabled={!value?.month}
              >
                {days.map(day => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </SelectInput>
            </FormControl>
          </div>
        </DatePartsContainer>
        <ClearButton onClick={handleClearAll} size="small">
          <ClearIcon />
        </ClearButton>
      </PartsWithClearButton>
      <StyledDescription>{schema?.description}</StyledDescription>
    </div>
  );
};
