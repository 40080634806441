import ArticleIcon from "@mui/icons-material/Article";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const CopyButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignSelf: "stretch",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "10px",
  },
}));

const StyledIconButton = styled(IconButton)({
  borderRadius: "4px",
});

const ButtonContent = styled("div")({
  marginRight: "8px",
  fontSize: ".8rem",
  fontWeight: "bold",
});

export const CopyBibliographyButton = ({ copyBibliography }) => {
  return (
    <CopyButtonContainer>
      <StyledIconButton onClick={copyBibliography}>
        <ArticleIcon />
        <ButtonContent>{"Bibliographie kopieren"}</ButtonContent>
      </StyledIconButton>
    </CopyButtonContainer>
  );
};
