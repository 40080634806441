import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import isEqual from "lodash/isEqual";
import React, { memo } from "react";
import { dateFields } from "../../config/schemaPropertiesGrouping";
import { CitationCollaborators } from "./collaborators/CitationCollaborators";
import { CitationDate } from "./date/CitationDate";

const TextFieldContainer = styled("div")(({ theme }) => ({
  marginTop: "1rem",
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CitationCommonFormInternal = ({
  formData,
  setFormData,
  fieldName,
  isError,
  isRequired,
  schema,
  collaboratorsRecommendedRoles,
}) => {
  if (dateFields.includes(fieldName)) {
    return (
      <div style={{ margin: "1rem" }}>
        <CitationDate
          value={formData}
          setValue={value => setFormData(formData => ({ ...formData, [fieldName]: value }))}
          fieldName={fieldName}
          schema={schema}
        />
      </div>
    );
  }

  if (fieldName === "collaborators") {
    return (
      <div style={{ marginTop: "1rem" }}>
        <CitationCollaborators
          value={formData ?? []}
          setFormData={setFormData}
          collaboratorsRecommendedRoles={collaboratorsRecommendedRoles}
        />
      </div>
    );
  }
  return (
    <TextFieldContainer>
      <TextField
        label={schema?.title ?? fieldName}
        value={formData ?? ""}
        onChange={e =>
          setFormData(formData => ({
            ...formData,
            [fieldName]: e.target.value === "" ? undefined : e.target.value,
          }))
        }
        error={isError}
        helperText={schema?.description}
        required={isRequired}
        fullWidth
        size={"small"}
      />
    </TextFieldContainer>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    isEqual(prevProps.formData, nextProps.formData) &&
    isEqual(prevProps.isError, nextProps.isError) &&
    isEqual(prevProps.fieldName, nextProps.fieldName) &&
    isEqual(prevProps.isRequired, nextProps.isRequired) &&
    isEqual(prevProps.schema, nextProps.schema) &&
    isEqual(prevProps.collaboratorsRecommendedRoles, nextProps.collaboratorsRecommendedRoles)
  );
}

export const CitationCommonForm = memo(CitationCommonFormInternal, arePropsEqual);
