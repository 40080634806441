const dateFields = ["accessed", "available-date", "event-date", "issued", "original-date", "submitted"];

const numberOrStringFields = ["id", "chapter-number", "citation-number", "collection-number", "edition", "issue", "locator", "number", "number-of-pages", "number-of-volumes", "page", "page-first", "part", "printing", "supplement", "volume", "first-reference-note-number"]

const stringFields = ["citation-key", "language", "journalAbbreviation", "short-title", "abstract", "annote", "archive", "archive_collection", "archive_location", "archive-place", "authority", "call-number", "citation-label", "collection-title", "container-title", "container-title-short", "dimensions", "division", "DOI", "event-title", "event-place", "genre", "ISBN", "ISSN", "jurisdiction", "keyword", "medium", "note", "original-publisher", "original-publisher-place", "original-title", "part-title", "PMCID", "PMID", "publisher", "publisher-place", "references", "reviewed-genre", "reviewed-title", "scale", "section", "source", "status", "title", "title-short", "URL", "version", "volume-title", "volume-title-short", "year-suffix"]

const roles = [
    "author",
    "collection-editor",
    "composer",
    "container-author",
    "director",
    "editor",
    "editorial-director",
    "interviewer",
    "illustrator",
    "original-author",
    "recipient",
    "reviewed-author",
    "translator"
]

const rolesTranslated = [
    { key: 'author', value: 'Autor' },
    { key: 'collection-editor', value: 'Container Editor' },
    { key: 'composer', value: 'Komponist' },
    { key: 'container-author', value: 'Container Autor' },
    { key: 'director', value: 'Regisseur' },
    { key: 'editor', value: 'Editor' },
    { key: 'editorial-director', value: 'Redaktionsleiter' },
    { key: 'interviewer', value: 'Interviewer' },
    { key: 'illustrator', value: 'Illustrator' },
    { key: 'original-author', value: 'Ursprünglicher Autor' },
    { key: 'recipient', value: 'Empfänger' },
    { key: 'reviewed-author', value: 'Rezensierter Autor' },
    { key: 'translator', value: 'Übersetzer' }
]


export {dateFields, numberOrStringFields, stringFields, roles, rolesTranslated};
