import EditNoteIcon from "@mui/icons-material/EditNote";
import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const StyledIconButton = styled(IconButton)({
  borderRadius: "4px",
});

const ManualCitationButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row-reverse",
  alignSelf: "stretch",
  alignItems: "center",
  marginBottom: "30px",
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    justifyContent: "center",
  },
}));

const ButtonContent = styled("div")({
  marginLeft: "4px",
  fontSize: ".8rem",
  fontWeight: "bold",
});

export const ManualCitationButton = ({ setNewManualCitationOpen }) => {
  return (
    <ManualCitationButtonContainer>
      <StyledIconButton onClick={() => setNewManualCitationOpen(true)}>
        <EditNoteIcon />
        <ButtonContent>{"Manuell zitieren"}</ButtonContent>
      </StyledIconButton>
    </ManualCitationButtonContainer>
  );
};
