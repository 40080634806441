import { FormControl, InputLabel, MenuItem } from "@mui/material";
import React from "react";
import { SelectInput } from "../input/SelectInput";

export function LanguageSelector({ language, setLanguage }) {
  const handleLanguageChange = e => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
  };

  return (
    language && (
      <FormControl fullWidth variant="outlined">
        <InputLabel id="language-selector-label">{"Zitiersprache"}</InputLabel>
        <SelectInput
          id="language-selector"
          labelId="language-selector-label"
          value={language}
          onChange={handleLanguageChange}
          label="Zitiersprache"
          MenuProps={{ disableScrollLock: true }}
        >
          <MenuItem value="de-DE">{"Deutsch"}</MenuItem>
          <MenuItem value="en-US">{"English"}</MenuItem>
        </SelectInput>
      </FormControl>
    )
  );
}
