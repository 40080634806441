import React from "react";
import { getMediaTypes } from "../../../logic/mediaTypeFiltering";
import { Checkbox, InputLabel, ListItemText, MenuItem } from "@mui/material";
import { SelectInput } from "../../input/SelectInput";
import { HeaderSelectContainer } from "./HeaderSelectContainer";

export const MediaTypeFilter = ({ data, selectedMediaTypes, handleMediaTypeChange }) => {
  const mediaTypes = getMediaTypes(data);

  return (
    <HeaderSelectContainer variant="outlined" size="small">
      <InputLabel id="media-type-select-label">{"Medientyp"}</InputLabel>
      <SelectInput
        id="media-type-select"
        name="media-type-select"
        labelId="media-type-select-label"
        multiple
        value={selectedMediaTypes}
        onChange={handleMediaTypeChange}
        renderValue={selected => selected.join(", ")}
        label="Medientyp"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 250,
              width: 250,
            },
          },
        }}
      >
        {mediaTypes.map(type => (
          <MenuItem key={type} value={type}>
            <Checkbox checked={selectedMediaTypes.indexOf(type) > -1} />
            <ListItemText primary={type} />
          </MenuItem>
        ))}
      </SelectInput>
    </HeaderSelectContainer>
  );
};
