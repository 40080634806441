import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CitationDashboard } from "./components/CitationDashboard";
import React from "react";
import "./App.css";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { ErrorBoundary } from "./components/errorBoundary/ErrorBoundary";

const App = () => {
  const [data, setData] = useLocalStorage("gWriters_citationData", []);

  const [style, setStyle] = useLocalStorage("gWriters_citationStyle", undefined);

  const [sortOption, setSortOption] = useLocalStorage("gWriters_citationSorting", "DEFAULT");

  const [language, setLanguage] = useLocalStorage("gWriters_citationLanguage", "de-DE");

  const theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: "0",
            padding: "0",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: ".8rem",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "0",
          },
          outlined: {
            color: "#FF6606",
          },
          containedSecondary: {
            color: "white",
            "&:hover": {
              backgroundColor: "#000000",
            },
          },
          containedPrimary: {
            color: "white",
            "&:hover": {
              backgroundColor: "#C14C00",
            },
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#FF6606",
        light: "#FFA369",
      },
      secondary: {
        main: "#999999",
        light: "#eaeaea",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component={"main"} style={{ padding: "0" }}>
        <ErrorBoundary clearData={() => setData([])}>
          <CitationDashboard
            data={data}
            setData={setData}
            style={style}
            setStyle={setStyle}
            sortOption={sortOption}
            setSortOption={setSortOption}
            language={language}
            setLanguage={setLanguage}
          />
        </ErrorBoundary>
      </Container>
    </ThemeProvider>
  );
};

export default App;
