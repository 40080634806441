const extractMediaType = (view) => {
  return view?.mediaType;
}

const extractCollaboratorInfo = (view) => {
  if (view && Array.isArray(view.collaborators) && view.collaborators.length > 0) {
    return view.collaborators.reduce((acc, collaborator) => {
      const name = collaborator.organization || collaborator.family;
      return name ? `${acc}${acc ? ", " : ""}${name}` : acc;
    }, "");
  }
  return undefined;
}

const extractPublicationYear = (view) => {
  return view?.issued?.year;
}

export const getDirectlyFetchedItemDetails = item => {
  return [extractMediaType(item?.view), extractPublicationYear(item?.view), extractCollaboratorInfo(item?.view)]
    .filter(it => it)
    .join(" · ");
};