import { Typography, styled } from "@mui/material";
import React from "react";

const SearchItemDetailContainer = styled("div")({
  width: "100%",
});

const SearchDetailTypography = styled(Typography)({
  color: "grey",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  display: "block",
});

const SearchDetailTitle = styled(Typography)({
  color: "grey",
});

export const SearchItemDetail = ({ title, details }) => {
  return (
    <SearchItemDetailContainer>
      <SearchDetailTitle variant="subtitle1">{title}</SearchDetailTitle>
      <SearchDetailTypography variant="caption">{details}</SearchDetailTypography>
    </SearchItemDetailContainer>
  );
};
