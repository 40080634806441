import styled from "@emotion/styled";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import React, { useState } from "react";
import { endpoints } from "../../endpoints";

const ImageContainer = styled("div")({
  margin: "5px 20px 5px 5px",
  height: "70px",
  minWidth: "70px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledImage = styled("img")({
  height: "70px",
  objectFit: "contain",
});

export const ImageComponent = ({ imageUrl, description }) => {
  const [imageError, setImageError] = useState(false);

  const getImageProxyUrl = () => {
    if (!imageUrl) {
      return undefined;
    }
    const encodedUrl = encodeURIComponent(imageUrl);
    return `${endpoints.citationBackend}/book/image?url=${encodedUrl}`;
  };

  return (
    <ImageContainer>
      {imageUrl && !imageError ? (
        <StyledImage src={getImageProxyUrl()} alt={description} onError={() => setImageError(true)} />
      ) : (
        <ImageSearchIcon style={{ fontSize: 40 }} />
      )}
    </ImageContainer>
  );
};
