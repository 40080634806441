import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { CitationStyleSelector } from "./CitationStyleSelector";
import { LanguageSelector } from "./LanguageSelector";

const NavigationButton = styled(Button)({
  margin: `5px 15px`,
});

const StyleSelectContainer = styled("div")(({ theme }) => ({
  padding: `20px 5px 20px 5px`,
  width: "70%",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

export const Preferences = ({ hidePreferences, style, setStyle, language, setLanguage, availableCitationStyles }) => {
  const [internalStyle, setInternalStyle] = useState(style);
  const [internalLanguage, setInternalLanguage] = useState(language);

  const confirm = () => {
    setStyle(internalStyle);
    setLanguage(internalLanguage);
    hidePreferences();
  };

  return (
    <>
      <StyleSelectContainer>
        <CitationStyleSelector
          setStyle={setInternalStyle}
          style={internalStyle}
          availableCitationStyles={availableCitationStyles}
        />
      </StyleSelectContainer>
      <StyleSelectContainer>
        <LanguageSelector language={internalLanguage} setLanguage={setInternalLanguage} />
      </StyleSelectContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <NavigationButton variant="contained" color="primary" onClick={confirm} disabled={!internalStyle}>
          {"Bestätigen"}
        </NavigationButton>
        <NavigationButton variant="contained" color="secondary" onClick={hidePreferences}>
          {"Zurück"}
        </NavigationButton>
      </div>
    </>
  );
};
