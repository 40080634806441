// utility methods used by sorting dropdown

const sortByCreatedOnAsc = items => {
  return items.slice().sort((a, b) => {
    const dateA = a.createdOn ? new Date(a.createdOn).getTime() : 0;
    const dateB = b.createdOn ? new Date(b.createdOn).getTime() : 0;
    return dateA - dateB;
  });
};

const sortByCreatedOnDesc = items => {
  return items.slice().sort((a, b) => {
    const dateA = a.createdOn ? new Date(a.createdOn).getTime() : 0;
    const dateB = b.createdOn ? new Date(b.createdOn).getTime() : 0;
    return dateB - dateA;
  });
};

const sortByTitleAsc = items => {
  return items.slice().sort((a, b) => {
    const titleA = a?.view?.title ?? "";
    const titleB = b?.view?.title ?? "";
    return titleA.localeCompare(titleB);
  });
};

const sortByTitleDesc = items => {
  return items.slice().sort((a, b) => {
    const titleA = a?.view?.title ?? "";
    const titleB = b?.view?.title ?? "";
    return titleB.localeCompare(titleA);
  });
};

const compareIssued = (a, b) => {
  const aIssued = a.view && a.view.issued ? a.view.issued : {};
  const bIssued = b.view && b.view.issued ? b.view.issued : {};

  const aYear = aIssued.year || 0;
  const bYear = bIssued.year || 0;
  const aMonth = aIssued.month || 0;
  const bMonth = bIssued.month || 0;
  const aDay = aIssued.day || 0;
  const bDay = bIssued.day || 0;

  if (aYear !== bYear) {
    return aYear - bYear;
  } else if (aMonth !== bMonth) {
    return aMonth - bMonth;
  } else {
    return aDay - bDay;
  }
};

const sortByIssuedAsc = items => {
  return items.slice().sort(compareIssued);
};

const sortByIssuedDesc = items => {
  return items.slice().sort((a, b) => compareIssued(b, a));
};

const sortData = (option, data) => {
  switch (option) {
    case "DEFAULT":
      return sortByCreatedOnAsc(data);
    case "TITLE_ASC":
      return sortByTitleAsc(data);
    case "TITLE_DESC":
      return sortByTitleDesc(data);
    case "PUB_DATE_ASC":
      return sortByIssuedAsc(data);
    case "PUB_DATE_DESC":
      return sortByIssuedDesc(data);
    case "DATE_ADDED_ASC":
      return sortByCreatedOnAsc(data);
    case "DATE_ADDED_DESC":
      return sortByCreatedOnDesc(data);
    default:
      return data;
  }
};

export { sortByCreatedOnAsc, sortByCreatedOnDesc, sortByTitleAsc, sortByTitleDesc, sortByIssuedAsc, sortByIssuedDesc, sortData };
