import { Autocomplete, FormControl, styled } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";

const StylesOptionsSubheader = styled("div")({
  color: "grey",
  padding: "15px",
});

export function CitationStyleSelector({ style, setStyle, availableCitationStyles }) {
  const handleStyleChange = (_event, newValue) => {
    const newStyle = availableCitationStyles.find(style => style.id === newValue?.id);
    setStyle(newStyle?.id);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <Autocomplete
        id="citation-style"
        options={availableCitationStyles}
        groupBy={option => (option.recommended ? "Häufig verwendet" : "Weitere")}
        getOptionLabel={option => option.label}
        value={availableCitationStyles.find(styleInfo => styleInfo.id === style)}
        onChange={handleStyleChange}
        renderInput={params => <TextField {...params} label={"Zitationsstil"} placeholder={"Zum Suchen tippen"} />}
        renderGroup={params => [
          <StylesOptionsSubheader key={params.key}>{params.group}</StylesOptionsSubheader>,
          params.children,
        ]}
      />
    </FormControl>
  );
}
