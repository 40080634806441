export const viewSchema = {
  "type": "object",
  "title": "Manuelle Zitierung",
  "required": ["title"],
  "properties": {
    "language": {
      "type": "string",
      "title": "Sprache",
      "description": "Die Sprache des Dokuments."
    },
    "short-title": {
      "type": "string",
      "title": "Kurztitel",
    },
    "collaborators": {
      "type": "array",
      "items": {
        "$ref": "#/definitions/collaborator"
      },
      "title": "Mitarbeiter",
      "description": "Liste der Personen oder Organisationen, die zu dem Dokument beigetragen haben."
    },
    "accessed": {
      "$ref": "#/definitions/dateParts",
      "title": "Zugegriffen",
      "description": "Datum, an dem auf das Dokument zugegriffen wurde."
    },
    "available-date": {
      "$ref": "#/definitions/dateParts",
      "title": "Verfügbarkeitsdatum",
      "description": "Datum, an dem das Dokument verfügbar wurde."
    },
    "event-date": {
      "$ref": "#/definitions/dateParts",
      "title": "Ereignisdatum",
      "description": "Datum des zugehörigen Ereignisses."
    },
    "issued": {
      "$ref": "#/definitions/dateParts",
      "title": "Ausgestellt",
      "description": "Ausstellungsdatum des Dokuments."
    },
    "original-date": {
      "$ref": "#/definitions/dateParts",
      "title": "Ursprungsdatum",
      "description": "Ursprüngliches Veröffentlichungsdatum."
    },
    "submitted": {
      "$ref": "#/definitions/dateParts",
      "title": "Eingereicht",
      "description": "Datum der Einreichung des Dokuments."
    },
    "archive": {
      "type": "string",
      "title": "Archiv",
      "description": "Name des Archivs, in dem das Dokument aufbewahrt wird."
    },
    "archive_collection": {
      "type": "string",
      "title": "Archivsammlung",
      "description": "Die spezifische Sammlung innerhalb des Archivs."
    },
    "archive_location": {
      "type": "string",
      "title": "Archivstandort",
      "description": "Spezifischer Standort des Dokuments im Archiv."
    },
    "archive-place": {
      "type": "string",
      "title": "Archivort",
      "description": "Ort des Archivs."
    },
    "authority": {
      "type": "string",
      "title": "Behörde",
      "description": "Die behördliche oder offizielle Instanz, die mit dem Dokument verbunden ist."
    },
    "call-number": {
      "type": "string",
      "title": "Rufnummer",
      "description": "Die Rufnummer des Dokuments in einer Bibliothek oder einem Archiv."
    },
    "chapter-number": {
      "type": "string",
      "title": "Kapitelnummer",
      "description": "Nummer des Kapitels im Dokument."
    },
    "collection-number": {
      "type": "string",
      "title": "Sammlungsnummer",
      "description": "Die Nummer der Sammlung, zu der das Dokument gehört."
    },
    "collection-title": {
      "type": "string",
      "title": "Sammlungstitel",
      "description": "Der Titel der Sammlung, zu der das Dokument gehört."
    },
    "container-title": {
      "type": "string",
      "title": "Behältertitel",
      "description": "Der Titel des übergeordneten Werks, in dem das Dokument enthalten ist."
    },
    "container-title-short": {
      "type": "string",
      "title": "Kurzform des Behältertitels",
      "description": "Eine verkürzte Form des Titels des übergeordneten Werks."
    },
    "dimensions": {
      "type": "string",
      "title": "Abmessungen",
      "description": "Die physischen Abmessungen des Dokuments oder des Objekts."
    },
    "division": {
      "type": "string",
      "title": "Abteilung",
      "description": "Die Abteilung oder Sektion, zu der das Dokument gehört."
    },
    "DOI": {
      "type": "string",
      "title": "DOI",
      "description": "Die Digital Object Identifier des Dokuments."
    },
    "edition": {
      "type": "string",
      "title": "Ausgabe",
      "description": "Die Ausgabe oder Version des Dokuments."
    },
    "event-title": {
      "type": "string",
      "title": "Ereignistitel",
      "description": "Der Titel des zugehörigen Ereignisses."
    },
    "event-place": {
      "type": "string",
      "title": "Ereignisort",
      "description": "Der Ort des zugehörigen Ereignisses."
    },
    "first-reference-note-number": {
      "type": "string",
      "title": "Nummer der ersten Referenzanmerkung",
      "description": "Die Nummer der ersten Anmerkung, die auf das Dokument verweist."
    },
    "genre": {
      "type": "string",
      "title": "Genre",
      "description": "Das Genre oder die Art des Dokuments."
    },
    "ISBN": {
      "type": "string",
      "title": "ISBN",
      "description": "Die International Standard Book Number des Buches."
    },
    "ISSN": {
      "type": "string",
      "title": "ISSN",
      "description": "Die International Standard Serial Number des Journals oder Magazins."
    },
    "issue": {
      "type": "string",
      "title": "Ausgabe",
      "description": "Die spezifische Ausgabe oder Nummer eines Journals oder Magazins."
    },
    "jurisdiction": {
      "type": "string",
      "title": "Gerichtsbarkeit",
      "description": "Die rechtliche Zuständigkeit oder das Gebiet, auf das sich das Dokument bezieht."
    },
    "keyword": {
      "type": "string",
      "title": "Schlüsselwort",
      "description": "Ein Wort oder eine Phrase, die den Inhalt des Dokuments beschreibt."
    },
    "locator": {
      "type": "string",
      "title": "Lokator",
      "description": "Ein Verweis oder Ort innerhalb des Dokuments oder einer Quelle."
    },
    "medium": {
      "type": "string",
      "title": "Medium",
      "description": "Das Medium, in dem das Dokument präsentiert wird, z.B. Print, Online, etc."
    },
    "note": {
      "type": "string",
      "title": "Anmerkung",
      "description": "Zusätzliche Informationen oder Bemerkungen zum Dokument."
    },
    "number": {
      "type": "string",
      "title": "Nummer",
      "description": "Eine spezifische Nummer, die das Dokument identifiziert."
    },
    "number-of-pages": {
      "type": "string",
      "title": "Seitenanzahl",
      "description": "Die Gesamtzahl der Seiten des Dokuments."
    },
    "number-of-volumes": {
      "type": "string",
      "title": "Bände Anzahl",
      "description": "Die Anzahl der Bände, falls das Werk mehrere Bände umfasst."
    },
    "original-publisher": {
      "type": "string",
      "title": "Ursprünglicher Verlag",
      "description": "Der Verlag, der das Originalwerk herausgegeben hat."
    },
    "original-publisher-place": {
      "type": "string",
      "title": "Ort des ursprünglichen Verlags",
      "description": "Der Ort, an dem der ursprüngliche Verlag ansässig ist."
    },
    "original-title": {
      "type": "string",
      "title": "Ursprünglicher Titel",
      "description": "Der ursprüngliche Titel des Werks, bevor es bearbeitet oder übersetzt wurde."
    },
    "page": {
      "type": "string",
      "title": "Seiten",
      "description": "Eine spezifische Seite oder ein Seitenbereich des Dokuments."
    },
    "part": {
      "type": "string",
      "title": "Teil",
      "description": "Ein bestimmter Abschnitt oder Teil des Dokuments."
    },
    "part-title": {
      "type": "string",
      "title": "Teiltitel",
      "description": "Der Titel eines bestimmten Abschnitts oder Teils des Dokuments."
    },
    "printing": {
      "type": "string",
      "title": "Druck",
      "description": "Informationen über den Druck des Dokuments."
    },
    "publisher": {
      "type": "string",
      "title": "Verlag",
      "description": "Die Firma oder Organisation, die das Dokument veröffentlicht hat."
    },
    "publisher-place": {
      "type": "string",
      "title": "Verlagsort",
      "description": "Der Ort, an dem der Verlag ansässig ist."
    },
    "references": {
      "type": "string",
      "title": "Referenzen",
      "description": "Querverweise oder zitierte Werke in Bezug auf das Dokument."
    },
    "reviewed-genre": {
      "type": "string",
      "title": "Rezensionsgenre",
      "description": "Das Genre des Werks, das rezensiert wurde."
    },
    "reviewed-title": {
      "type": "string",
      "title": "Rezensierter Titel",
      "description": "Der Titel des Werks, das rezensiert wurde."
    },
    "scale": {
      "type": "string",
      "title": "Maßstab",
      "description": "Der Maßstab, in dem ein Objekt oder Dokument dargestellt wird."
    },
    "section": {
      "type": "string",
      "title": "Abschnitt",
      "description": "Ein spezifischer Abschnitt oder Teil innerhalb des Dokuments."
    },
    "source": {
      "type": "string",
      "title": "Quelle",
      "description": "Die Herkunft oder der Ursprung des Dokuments oder der Information."
    },
    "status": {
      "type": "string",
      "title": "Status",
      "description": "Der aktuelle Zustand oder Fortschritt des Dokuments oder der Arbeit."
    },
    "supplement": {
      "type": "string",
      "title": "Ergänzung",
      "description": "Zusätzliche Informationen oder Materialien, die das Hauptdokument ergänzen."
    },
    "title": {
      "type": "string",
      "title": "Titel",
    },
    "title-short": {
      "type": "string",
      "title": "Kurztitel",
      "description": "Eine verkürzte oder abgekürzte Form des Haupttitels."
    },
    "URL": {
      "type": "string",
      "title": "URL",
      "description": "Die Webadresse oder der Link zum Dokument oder zur Ressource."
    },
    "version": {
      "type": "string",
      "title": "Version",
      "description": "Die spezifische Ausgabe oder Revision des Dokuments."
    },
    "volume": {
      "type": "string",
      "title": "Band",
      "description": "Ein spezifischer Band eines mehrbändigen Werks."
    },
    "volume-title": {
      "type": "string",
      "title": "Bandtitel",
      "description": "Der Titel eines spezifischen Bandes eines mehrbändigen Werks."
    },
    "volume-title-short": {
      "type": "string",
      "title": "Kurzer Bandtitel",
      "description": "Eine verkürzte oder abgekürzte Form des Bandtitels."
    },
    "year-suffix": {
      "type": "string",
      "title": "Jahreszusatz",
      "description": "Ein Zusatz oder eine Erweiterung, um Jahre in Zitaten zu unterscheiden."
    }
  },
  "definitions": {
    "role": {
      "enum": [
        "author",
        "chair",
        "collection-editor",
        "compiler",
        "composer",
        "container-author",
        "contributor",
        "curator",
        "director",
        "editor",
        "editorial-director",
        "executive-producer",
        "guest",
        "host",
        "interviewer",
        "illustrator",
        "narrator",
        "organizer",
        "original-author",
        "performer",
        "producer",
        "recipient",
        "reviewed-author",
        "script-writer",
        "series-creator",
        "translator"
      ],
      "title": "Rolle",
      "description": "Die spezifische Funktion oder Position des Mitarbeiters im Zusammenhang mit dem Dokument oder Werk."
    },
    "collaborator": {
      "title": "Mitarbeiter",
      "anyOf": [
        {
          "type": "object",
          "title": "Person",
          "properties": {
            "family": {
              "type": "string",
              "title": "Nachname",
              "description": "Der Nachname oder Familienname des Mitarbeiters."
            },
            "given": {
              "type": "string",
              "title": "Vorname",
              "description": "Der Vorname des Mitarbeiters."
            },
            "role": {
               "$ref": "#/definitions/role"
            }
          }
        },
        {
          "type": "object",
          "title": "Organisation",
          "properties": {
            "organization": {
              "type": "string",
              "title": "Organisation",
              "description": "Die Organisation oder Firma, der der Mitarbeiter angehört."
            },
            "role": {
              "$ref": "#/definitions/role"
           }
          }
        }
      ]
    },
    "dateParts": {
      "type": "object",
      "properties": {
        "year": {
          "type": "integer",
          "title": "Jahr",
          "description": "Das Jahr des Datums."
        },
        "month": {
          "type": "integer",
          "title": "Monat",
          "description": "Der Monat des Datums."
        },
        "day": {
          "type": "integer",
          "title": "Tag",
          "description": "Der Tag des Datums."
        }
      }
    }
  }
}
