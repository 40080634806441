const { useCallback } = require("react");

export const useClipboard = (
  data,
  openSnackbar
) => {
  const copyBibliographyEntry = useCallback(
    (bibliographyTextEntry, bibliographyHtmlEntry) => {
      const item = new ClipboardItem({
        "text/plain": new Blob([bibliographyTextEntry], { type: "text/plain" }),
        "text/html": new Blob([bibliographyHtmlEntry], { type: "text/html" }),
      });

      navigator.clipboard
        .write([item])
        .then(() => {
          openSnackbar(`Bibliographieeintrag kopiert: ${bibliographyTextEntry}`, 'success');
        })
        .catch((err) => {
          openSnackbar(`Fehler beim Kopieren des Bibliographieeintrags`, 'error');
        });
    },
    [openSnackbar]
  );

  const copyInTextCitation = useCallback(
    (inTextCitationText, inTextCitationHtml) => {
      const item = new ClipboardItem({
        "text/plain": new Blob([inTextCitationText], { type: "text/plain" }),
        "text/html": new Blob([inTextCitationHtml], { type: "text/html" }),
      });

      navigator.clipboard
        .write([item])
        .then(() => {
          openSnackbar(`Textzitat kopiert: ${inTextCitationText}`, 'success');

        })
        .catch((err) => {
          openSnackbar(`Fehler beim Kopieren des Textzitats`, 'error');
        });
    },
    [openSnackbar]
  );

  const copyBibliography = useCallback(() => {
    const textParts = data.map((entry) => entry?.bibliographyTextEntry);
    const htmlParts = data.map((entry) => entry?.bibliographyHtmlEntry);

    const item = new ClipboardItem({
      "text/plain": new Blob(textParts, { type: "text/plain" }),
      "text/html": new Blob(htmlParts, { type: "text/html" }),
    });

    navigator.clipboard
      .write([item])
      .then(() => {
        openSnackbar("Bibliografie wurde in die Zwischenablage kopiert", 'success');

      })
      .catch((err) => {
        openSnackbar("Fehler beim Kopieren der Bibliografie in die Zwischenablage", 'error');

      });
  }, [openSnackbar, data]);

  return { copyBibliographyEntry, copyInTextCitation, copyBibliography };
};
