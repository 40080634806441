import { Alert, FormControl, InputLabel, MenuItem, Snackbar, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as id } from "uuid";
import { manualViewsConfig } from "../../config/manualViews";
import { viewSchema } from "../../schema/viewSchema";
import { SelectInput } from "../input/SelectInput";
import { CitationCommonForm } from "./CitationForm";
import { ExtendedFields } from "./ExtendedFields";

const ButtonStyles = {
  margin: "0 10px 0 10px",
};

const MediaTypeContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MediaTypeSelectContainer = styled("div")(({ theme }) => ({
  margin: "1rem 0 1rem 1rem",
  width: "250px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "10px 0 10px 0",
  },
}));

export const ManualCitation = ({ citationData, onCitationSubmit, onCancel, mediaTypeForNewEntry, moveScroll }) => {
  const getMediaType = () => {
    if (mediaTypeForNewEntry) {
      return mediaTypeForNewEntry;
    }
    if (citationData?.mediaType) {
      return citationData.mediaType;
    }
    return manualViewsConfig.find(config => config.mediaType === "Sonstige")?.mediaType;
  };

  const getCslType = selectedMediaType =>
    manualViewsConfig.find(it => it.mediaType === selectedMediaType)?.cslType ?? citationData?.type ?? "document";

  const [mediaType, setMediaType] = useState(getMediaType());

  const [formData, setFormData] = useState(citationData ?? { id: id(), type: getCslType(mediaType), mediaType });

  const { description, mainProperties, requiredProperties, overrides, collaboratorsRecommendedRoles } = manualViewsConfig.find(
    it => it.mediaType === mediaType
  );

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const advancedProperties = Object.keys(viewSchema.properties).filter(key => !mainProperties.includes(key));

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const onSubmit = () => {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setOpenSnackbar(true);
    } else {
      onCitationSubmit(formData);
    }
  };

  const validate = () => {
    if (!formData.title) {
      return { title: ["Titel muss angegeben werden."] };
    }
    return {};
  };

  const renderMainInputs = () => {
    return mainProperties.map(key => {
      return (
        <div key={key}>
          <CitationCommonForm
            formData={formData[key]}
            setFormData={setFormData}
            fieldName={key}
            schema={overrides?.[key] ? overrides[key] : viewSchema.properties[key]}
            isError={!!validationErrors[key]}
            isRequired={requiredProperties.includes(key)}
            collaboratorsRecommendedRoles={collaboratorsRecommendedRoles}
          />
        </div>
      );
    });
  };

  const renderAdvancedInputs = useCallback(() => {
    return advancedProperties.map(key => {
      return (
        <div key={key}>
          <CitationCommonForm
            formData={formData[key]}
            setFormData={setFormData}
            fieldName={key}
            schema={overrides?.[key] ? overrides[key] : viewSchema.properties[key]}
            isError={!!validationErrors[key]}
            isRequired={requiredProperties.includes(key)}
            collaboratorsRecommendedRoles={collaboratorsRecommendedRoles}
          />
        </div>
      );
    });
    // eslint-disable-next-line
  }, [formData, setFormData, validationErrors, mediaType]);

  const renderDescription = () => {
    if (description) {
      return (
        <Typography variant="body2" style={{ color: "grey" }} sx={{ margin: ".5rem 0 2rem 1rem" }}>
          {description}
        </Typography>
      );
    }
    return null;
  };

  const renderMediaType = () => {
    return (
      <MediaTypeContainer>
        <Typography variant="h4" style={{ color: "grey" }} sx={{ margin: "1rem 0 0 1rem" }}>
          {mediaType}
        </Typography>
        {renderMediaTypeSelect()}
      </MediaTypeContainer>
    );
  };

  const handleMediaTypeChange = e => {
    const selectedMediaType = e.target.value;
    setMediaType(selectedMediaType);
    const cslType = manualViewsConfig.find(it => it.mediaType === selectedMediaType)?.cslType ?? citationData?.type ?? "document";
    setFormData({ ...formData, mediaType: selectedMediaType, type: cslType });
  };

  const renderMediaTypeSelect = () => {
    return (
      <MediaTypeSelectContainer>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="media-type-selector-label">{"Medientyp"}</InputLabel>
          <SelectInput
            id="media-type-selector"
            labelId="media-type-selector-label"
            value={mediaType}
            onChange={handleMediaTypeChange}
            label="Medientyp"
            style={{ width: "100%" }}
            size="small"
          >
            {manualViewsConfig.map(typeConfig => {
              return (
                <MenuItem value={typeConfig.mediaType} key={typeConfig?.mediaType}>
                  {typeConfig?.mediaType}
                </MenuItem>
              );
            })}
          </SelectInput>
        </FormControl>
      </MediaTypeSelectContainer>
    );
  };

  return (
    <Container>
      {renderMediaType()}
      {renderDescription()}
      {renderMainInputs()}
      <ExtendedFields
        showAdvanced={showAdvanced}
        setShowAdvanced={setShowAdvanced}
        mounted={mounted}
        renderAdvancedInputs={renderAdvancedInputs}
        moveScroll={moveScroll}
      />
      <div>
        <Button variant="contained" color="primary" type="submit" style={ButtonStyles} onClick={onSubmit}>
          {"Bestätigen"}
        </Button>
        <Button variant="contained" color="secondary" onClick={onCancel} style={ButtonStyles}>
          {"Zurück"}
        </Button>
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: "100%" }}>
          {Object.values(validationErrors).flat().join("\n")}
        </Alert>
      </Snackbar>
    </Container>
  );
};
